import React from 'react'

//Material UI Components
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import DialogTitle from "@mui/material/DialogTitle";
import LifeSherpaLoading from '../LifeSherpaLoading';
import Button from '@mui/material/Button';

import { connect } from 'react-redux';
//Components
import SingleSelectionItem from './SingleSelectionItem'
import MultiSelectionItem from './MultiSelectionItem'
import TextAreaItem from './TextAreaItem'
import TextInpuItem from './TextInpuItem'
import TimerOptionItem from './TimerOptionItem'
import OverallEvaluation from './OverallEvaluation'
import SpacerItem from './SpacerItem'
import DateTimeInputType from "./DateTimeInputType";
import Popover from '@mui/material/Popover';
import { savePokrFormForClients } from '../../actions/PokeFormActions'
import { sendStepsNotesFormResponse, sendEvaluationFormResponse, clearPokeFormData, setPokeFormForGroup, saveFormForClient } from "../../actions";
import LinearProgressWithValueLabel from '../LifeSherpaLoading/LinearProgressWithValueLabel';

class ActivityConfirmForm extends React.Component {

  state = {
    evaluationData: null,
    stepsNotesData: null,
    labelValue: 0,
    popoverTest: "Please fill the required sections",
    showNameWarning: false,
    anchorEl: null,
    showError: false,
    formSumbmitted: false
  }


  updateform(obj, section) {
    let evaluationData = this.props.pokeForm
    Object.keys(evaluationData.sections).sort((a, b) => evaluationData.sections[a].position - evaluationData.sections[b].position).map((evaluatioObj, key) => {
      if (section === key) {
        evaluationData.sections[evaluatioObj] = obj
      }
    })
    this.setState({ evaluationData })
  }

  handleEvaluationForm(e, obj, section) {
    let evaluationSectionData = obj
    ////console.log('target value-->', e.target.value, e.target.label, e.target.id, e.target.name, section)
    if (obj.type === 'checklist' && !obj.isMultiValue) {
      evaluationSectionData.itemsList.map((lbl, key) => {
        if (e.target.id === `radio-${obj.heading}-${key}-${section}`) {
          lbl.value = e.target.checked
        } else {
          lbl.value = !e.target.checked
        }
      })

    } else if (obj.type === 'checklist') {
      evaluationSectionData.itemsList.map((lbl, key) => {
        if (e.target.id === `checkbox-${key}-${lbl.name}-${section}`) {
          lbl.value = e.target.checked
        }
      })

    } else if (obj.type === 'longText') {
      evaluationSectionData['text'] = e.target.value

    } else if (obj.type === 'text') {
      evaluationSectionData['text'] = e.target.value
    } else if (obj.type === 'spacer') {

    } else {

    }
    this.updateform(evaluationSectionData, section);
  }
  updateform = (obj, section) => {
    let { evaluationData } = this.state;
    if (!evaluationData) {
      console.log(" updating poke form data for changes ....")
      evaluationData = this.props.pokeForm
    }
    Object.keys(evaluationData.sections).sort((a, b) => evaluationData.sections[a].position - evaluationData.sections[b].position).map((evaluatioObj, key) => {
      if (section === key) {
        evaluationData.sections[evaluatioObj] = obj
      }
    })
    ////console.log('updated value-->', evaluationData)
    this.setState({ evaluationData: evaluationData })
  }

  handleUp(obj, sectionObj) {
    obj.value = "up"
    console.log(" thumb down --> ", sectionObj)
    this.updateEvaluationForm(obj, sectionObj);
  }

  handleDateAndTimeSection(obj, sectionObj) {
    console.log(" date changed --> ", sectionObj, obj)
    this.updateEvaluationForm(obj, sectionObj);
  }

  handleDown(obj, sectionObj) {
    obj.value = "down"
    console.log(" thumb down --> ", sectionObj);
    this.updateEvaluationForm(obj, sectionObj);
  }

  handlePlus(obj, section, sectionObj) {
    console.log(obj, section, sectionObj);
    let value = typeof obj.value === 'number' ? obj.value : obj.minValue || 0;
    if (value < obj.maxValue) {
      obj.value = value + 1;
    }
    this.updateEvaluationForm(obj, sectionObj);
  }

  handleMinus(obj, section, sectionObj) {
    console.log(obj, section, sectionObj);
    let value = typeof obj.value === 'number' ? obj.value : obj.minValue || 0;
    if (value > obj.minValue) {
      obj.value = value - 1;
    }
    this.updateEvaluationForm(obj, sectionObj);
  }
  updateEvaluationForm = (obj, sectionObj) => {
    let { evaluationData } = this.state;
    if (!evaluationData) {
      console.log(" updating poke form data for changes ....")
      evaluationData = this.props.pokeForm
    }
    evaluationData.sections[sectionObj] = obj;
    this.setState({ evaluationData: evaluationData })
  }

  handleSaveAndContinue = (e) => {
    const { pokeForm } = this.props;
    let { evaluationData } = this.state;
    let isAnySectionMandatory = false;
    pokeForm && pokeForm.sections && Object.keys(pokeForm.sections).map((obj, key) => {
      let sectionObj = pokeForm.sections[obj];
      if (sectionObj.isMandatory) {
        isAnySectionMandatory = true;
      }
    })
    if (!evaluationData) {
      evaluationData = pokeForm;
    }
    //console.log("evaluationData --> ", evaluationData.sections);
    let sectionIndex = evaluationData && evaluationData.sections && Object.keys(evaluationData.sections).findIndex((obj, key) => {
      let sectionObj = evaluationData.sections[obj];
      let isInvalid = false;
      if (sectionObj.isMandatory) {
        if ((sectionObj.type == 'text') && (!sectionObj.text)) {
          isInvalid = true;
        } else if (sectionObj.type == 'longText' && (!sectionObj.text)) {
          isInvalid = true
        } else if (sectionObj.type == 'checklist' && sectionObj.itemsList && sectionObj.itemsList.findIndex(item => item.value) == -1) {
          isInvalid = true
        } else if (sectionObj.type == 'number' && (!sectionObj.value)) {
          isInvalid = true
        } else if (sectionObj.type == 'thumbs' && (!sectionObj.value)) {
          isInvalid = true
        } else if (sectionObj.type == 'date' && (!sectionObj.date)) {
          isInvalid = true
        }
      }
      return isInvalid;
    })
    if (sectionIndex == -1) {
      console.log(" form completed ")
      this.handleSendFormResponse();
    } else {
      this.setState({ showNameWarning: true, anchorEl: e.currentTarget, showError: true });
    }
  }

  handleSendFormResponse() {
    let { evaluationData } = this.state;
    let { pokeForm, formId, achievementId, selectedActivitiesHistory, selectedActivity, groups, orgId, selectedMemberId, widgetApp } = this.props;
    if (!achievementId && !this.props.isPokeForGroup && selectedActivitiesHistory) {
      achievementId = selectedActivitiesHistory.id
    }
    const payloadData = evaluationData ? evaluationData : pokeForm;
    groups = this.props.selectedGroup && this.props.selectedGroup.length > 0 ? this.props.selectedGroup : groups

    if (this.props.isPokeForClient && widgetApp) {
      this.setState({ evaluationData: null, formSumbmitted: true }, () => {
        this.props.saveFormForClient(selectedMemberId, payloadData, formId, orgId)
      });
    } else {
      if (this.props.isPokeForGroup) {
        this.props.setPokeFormForGroup(groups, payloadData, formId)
      } else if (this.props.isPokeForClient) {
        this.props.savePokrFormForClients(this.props.selectedMemberId, payloadData, formId, selectedActivity)
      } else {
        if (this.props.selectedStepId) {
          this.props.sendStepsNotesFormResponse(this.props.selectedMemberId, achievementId, payloadData, formId, this.props.selectedStepId)
        } else {
          this.props.sendEvaluationFormResponse(this.props.selectedMemberId, achievementId, payloadData, formId)
        }
      }
      this.setState({ evaluationData: null });
      // this.props.clearPokeFormData()
      this.props.handleClose()
    }
    
  }

  handleCancel() {
    this.setState({ evaluationData: null, formSumbmitted: false})
    this.props.clearPokeFormData()
    this.props.handleClose();

  }

  render() {
    let { showNameWarning, anchorEl, showError, evaluationData, formSumbmitted } = this.state;
    let { pokeForm, title, isPokeForGroup, description, widgetApp } = this.props;
    if (pokeForm && pokeForm.formName) {
      title = pokeForm.formName
    }
    if (evaluationData) {
      pokeForm = evaluationData
    }
    return (
      <>
      {this.props.open ?
        <Dialog
          fullWidth={true}
          maxWidth='xs'
          open={this.props.open}
          // onClose={this.handleCancel.bind(this)}
          aria-labelledby="responsive-dialog-title"
          className={this.props.popupThemeClass}
        >
          <DialogTitle style={{ borderBottom: '1px solid', }}>
            {this.props.isPokeForGroup ?
              <div className="d-flex align-items-center justify-content-between  bg-lifesherpa p-10">
                <div className="text-white media-body pt-5">
                  <h2 className="mb-0">{title}</h2>
                  <h4 className="mb-0">{description}</h4>
                </div>
                <IconButton className="header-close-icon-button" title="Close Poke Form" onClick={this.handleCancel.bind(this)}>
                  <CloseIcon />
                </IconButton>
              </div>
              :
              <div className="d-flex align-items-center justify-content-between bg-lifesherpa p-2">
                <div className="d-flex align-items-center">
                  <div className="media-left position-relative mr-10">
                    <img src={this.props.profileImage || require('Assets/avatars/user-15.jpg')} className="img-fluid rounded-circle" alt="user profile" width="40" height="40" />
                  </div>
                  <div className="media-body text-white pt-5">
                    <h2 className="mb-0">{this.props.displayName ? this.props.displayName : 'Client'}</h2>
                    <h4 className="mb-0">{title ? title : 'Routine'}</h4>
                  </div>
                </div>
                <IconButton className="header-close-icon-button" title="Close Poke Form" onClick={this.handleCancel.bind(this)}>
                  <CloseIcon />
                </IconButton>
              </div>
            }
          </DialogTitle>
          <DialogContent className="p-0 ">
            { widgetApp && formSumbmitted ?
              <div className='p-3'>
                <h2>Processing your submission...</h2>
                <h4>The workflow is being started using the form data.</h4>
                <div style={{ height: "350px" }}>
                  <div className='p-3 mt-3 progressbar_style'>
                    <LinearProgressWithValueLabel loading={this.props.loading} handleClose={this.handleCancel.bind(this)}/>
                  </div>
                </div>
              </div>
              : 
              <>
                <LifeSherpaLoading loading={this.props.loading}/>
                  <div className="activity-preview-form-container ">
                    <div className="form-body-background pb-0 pt-0">
                      {pokeForm && pokeForm.sections ? Object.keys(pokeForm.sections).map((obj, key) => (
                        pokeForm.sections[obj].type === 'checklist' && !pokeForm.sections[obj].isMultiValue ? 
                          <SingleSelectionItem 
                            key={key + "singleSelectionItem"} 
                            section={key} showError={showError} 
                            updateform={this.handleEvaluationForm.bind(this)} 
                            obj={pokeForm.sections[obj]} 
                            />
                        : pokeForm.sections[obj].type === 'checklist' ? 
                          <MultiSelectionItem 
                            obj={pokeForm.sections[obj]} 
                            key={key + "multiSelectionItem"} 
                            section={key} 
                            showError={showError} 
                            updateform={this.handleEvaluationForm.bind(this)} 
                            />
                        : pokeForm.sections[obj].type === 'number' ? 
                          <TimerOptionItem 
                            obj={pokeForm.sections[obj]} 
                            key={key + "timerOptionItem"} 
                            section={key} sectionObj={obj} 
                            showError={showError} 
                            handleMinus={this.handleMinus.bind(this)}
                            handlePlus={this.handlePlus.bind(this)} 
                            labelValue={this.state.labelValue} 
                            updateform={this.handleEvaluationForm.bind(this)}
                            />
                        : pokeForm.sections[obj].type === 'longText' ? 
                          <TextAreaItem 
                            obj={pokeForm.sections[obj]} 
                            key={key + "textAreaItem"} 
                            section={key} 
                            showError={showError} 
                            updateform={this.handleEvaluationForm.bind(this)} 
                            />
                        : pokeForm.sections[obj].type === 'text' ? 
                          <TextInpuItem 
                            obj={pokeForm.sections[obj]} 
                            key={key + "textInputItem"} 
                            section={key} 
                            showError={showError} 
                            updateform={this.handleEvaluationForm.bind(this)} 
                            />
                        : pokeForm.sections[obj].type === 'spacer' ? 
                          <SpacerItem 
                            obj={pokeForm.sections[obj]} 
                            key={key + "spacerItem"} 
                            section={key} 
                            showError={showError} 
                            updateform={this.handleEvaluationForm.bind(this)} 
                            />
                        : pokeForm.sections[obj].type === 'date' ? 
                          <DateTimeInputType 
                            obj={pokeForm.sections[obj]} 
                            key={key + "spacerItem"} 
                            section={key} 
                            showError={showError} 
                            sectionObj={obj} 
                            handleDateAndTimeSection={this.handleDateAndTimeSection.bind(this)} 
                            />
                        : <OverallEvaluation 
                          obj={pokeForm.sections[obj]} 
                          sectionObj={obj} 
                          selectedThumb={this.state.selectedThumb} 
                          handleUp={this.handleUp.bind(this)} 
                          key={key + "overevaluationItem"} 
                          section={key} 
                          showError={showError} 
                          handleDown={this.handleDown.bind(this)} 
                          />
                      )) :
                        <div className="" style={{ height: "300px" }}></div>
                      }
                    </div>
                </div>
              </>
            }
          </DialogContent>
          <DialogActions className=" d-flex align-items-center justify-content-center" style={{ borderTop: '1px solid' }}>
            {widgetApp && formSumbmitted ?
              <Button size="small" disabled={this.props.disabled} onClick={this.handleCancel.bind(this)} className=" border primary-button" style={{ width: 100 }}>
                  OK
              </Button>
              :
              <>
                <Popover
                  open={showNameWarning}
                  onClose={() => this.setState({ showNameWarning: false })}
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <Typography className="p-3 base-font-color">{this.state.popoverTest}</Typography>
                </Popover>
                <Button size="small" disabled={this.props.disabled} onClick={(e) => this.handleSaveAndContinue(e)} className=" border primary-button" style={{ width: 100 }}>
                  {(isPokeForGroup || this.props.isPokeForClient ) && (!widgetApp) ? "Poke" : 'Submit'}
                </Button>
              </>
            }
            
          </DialogActions>
        </Dialog>
        : <></>
        }
      </>
    )
  }
}
const mapStateToProps = ({ settings, GroupList }) => {
  const { popupThemeClass } = settings;
  const {groups} = GroupList
  return { popupThemeClass, groups};
};
export default connect(mapStateToProps, {
  savePokrFormForClients,
  sendStepsNotesFormResponse,
  sendEvaluationFormResponse,
  clearPokeFormData,
  setPokeFormForGroup,
  saveFormForClient
})(ActivityConfirmForm);