import {
    GET_CLIENT_POKE_FORM,
    GET_CLIENT_SUCCESS_POKE_FORM,
    SET_CLIENT_POKE_FORM,
    SET_CLIENT_SUCCESS_POKE_FORM,
    UPDATE_CLIENT_POKE_FORM,
    UPDATE_CLIENT_SUCCESS_POKE_FORM,
    SET_SELECTED_POKE_FOR_GROUP,
    SET_GROUP_POKE_FORM,
    SET_GROUP_SUCCESS_POKE_FORM,
    SHOW_LOADING_INDICATOR_CLIENT_POKE_FORM,
    HIDE_LOADING_INDICATOR_CLIENT_POKE_FORM,
    CLEAR_POKE_FORM_DATA,
    GET_GROUP_POKE_FORM,
    GET_GROUP_POKE_FORM_SUCCESS,
    GET_GROUP_POKE_FORM_FAILED,
    GET_POKE_FORM_OF_CLIENT,
    GET_POKE_FORM_OF_CLIENT_SUCCESS,
    GET_POKE_FORM_OF_CLIENT_FAILED
 } from '../actions/types';

 const INITIAL_STATE = {
    pokeForm: null,
    loading: false,
    openPokeForm: false,
    routineTitle: '',
    isPokeForGroup: false,
    groupPokeForm:null,
    groupPokeFormLoading: false,
    clientPokeForm: null,
    clientPokeFormLoading: false,
    responseMessage: null,
    errorResponseMessage: null
};

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case GET_CLIENT_POKE_FORM:
            return { ...state, loading: true, pokeForm: null };
        case SET_SELECTED_POKE_FOR_GROUP:
            return { ...state, isPokeForGroup: action.payload };
        // get client poke form success
        case GET_CLIENT_SUCCESS_POKE_FORM:
            return { ...state, pokeForm: action.payload, loading: false };
        case SET_CLIENT_POKE_FORM:
            return { ...state, loading: true, clientPokeFormLoading: true };
        // get client poke form success
        case SET_CLIENT_SUCCESS_POKE_FORM:
            return { ...state, loading: false, clientPokeFormLoading: false,  responseMessage: action.payload.msg || action.payload.message};
        case SET_GROUP_POKE_FORM:
            return { ...state, loading: true, groupPokeFormLoading: true };
        // get client poke form success
        case SET_GROUP_SUCCESS_POKE_FORM:
            return { ...state, loading: false, groupPokeFormLoading: false};
        // show loading 
        case SHOW_LOADING_INDICATOR_CLIENT_POKE_FORM:
            return { ...state, loading: true };
        // hide loading 
        case HIDE_LOADING_INDICATOR_CLIENT_POKE_FORM:
            return { ...state, loading: false, groupPokeFormLoading: false, clientPokeFormLoading: false};
        case CLEAR_POKE_FORM_DATA:
            return { ...state, pokeForm: null , groupPokeForm: null, clientPokeForm: null, loading: false, groupPokeFormLoading: false, clientPokeFormLoading: false};
        case GET_GROUP_POKE_FORM:
            return { ...state, groupPokeFormLoading: true, groupPokeForm: null };
        case GET_GROUP_POKE_FORM_SUCCESS:
            return { ...state, groupPokeFormLoading: false,  groupPokeForm: action.payload};
        case GET_GROUP_POKE_FORM_FAILED:
            return { ...state, groupPokeFormLoading: false};
        case GET_POKE_FORM_OF_CLIENT:
             return { ...state, clientPokeFormLoading: true, clientPokeForm: null, errorResponseMessage: null, responseMessage: null};
        case GET_POKE_FORM_OF_CLIENT_SUCCESS:
            return { ...state, clientPokeFormLoading: false, clientPokeForm: action.payload};
        case GET_POKE_FORM_OF_CLIENT_FAILED:
            return { ...state, clientPokeFormLoading: false, errorResponseMessage: action.payload, responseMessage: null};

        default: return { ...state };
    }
}
