import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {auth, firebase} from '../../firebase';
import { NotificationManager } from 'react-notifications';
import SweetAlert from 'react-bootstrap-sweetalert'
import NotificationsIcon from '@mui/icons-material/Notifications';
import { getToken, onMessage, getMessaging, isSupported } from "firebase/messaging";
import {delay} from "../../helpers/helpers";
import { 
  getHelpContactsWithChannel,
  hideChatPanel, 
  showFullChatPanelForSelectedUser,
  registerDeviceForNotification,
  showVideoCallConfirmation,
  showMissedCallConfirmation,
  displayPushNotiAlertPopup,
  showExecuteActivityConfirmation,
  hidePushNotiAlert,
  networkActivity,
  handleActivityCompletionConfirmation,
  showNotificationPopup,
  getUserInfo,
  hideVideoRoomPanel,
  getActivitiesList,
  getResourceList,
  getcategories,
  getKeywords,
  getActivitiesListN,
  showExecuteActivityPannel
} from 'Actions';

import AppConfig from "../../constants/AppConfig"

class NotificationsHandler extends Component {

  state = {
    permissionStatus:"",
    showEnableAlert: false
  }

  //Component Life Cycle Method
  
  componentDidMount() {
    const { hideVideoRoomPanel, hideChatPanel, getHelpContactsWithChannel, displayPushNotiAlertPopup } = this.props;

    hideVideoRoomPanel();
    hideChatPanel();
    this.addPostMessageCall();

    isSupported().then(result => {
      if (result) {
        this.handleNotificationPermission();
        console.log(" messaging supported",)
      } else {
        getHelpContactsWithChannel();
        displayPushNotiAlertPopup(true)
        console.log(" messaging not supported",)
      }

    }).catch(error => {
      getHelpContactsWithChannel();
      displayPushNotiAlertPopup(true)
      console.log(" messaging not supported",)
    })
    
  }

  handleNotificationPermission = () => {
    let permissionStatus = "";
    if("Notification" in window) { 
      permissionStatus = Notification.permission;
      console.log(" permission status --> ", permissionStatus);
      if(permissionStatus === "default") {
        setTimeout(() => {
          this.setState({permissionStatus : permissionStatus, showEnableAlert: true});
        }, 4000)
      } else {
        this.getFirebaseMessagingToken();
        this.setState({permissionStatus : permissionStatus});
      }
    } else {
      this.getFirebaseMessagingToken()
    }
  }

  getFirebaseMessagingToken = () => {
    try {
      const messaging = getMessaging(firebase);
      this.turnOnNotification(messaging).then((currentToken) => {
        if(currentToken) {
            localStorage.setItem("fcmToken",currentToken);
            this.registerDeviceForNotification(currentToken);
            this.props.getHelpContactsWithChannel();
            if (this.state.permissionStatus === "default") {
              NotificationManager.success('Notification permission enabled!')
            }
            console.log(" Message receiving enabled for foreground")
            onMessage(messaging, (message) => {
              let notification = message && message.data ? message.data : {};
              console.log(" Message received -->",message, "notification -->", notification);
              if(notification.twi_body && notification.conversation_title /*&& auth.currentUser*/) {
                this.handleTwiolioNotification(notification.conversation_title, notification.twi_body, notification.author);
              } else if(notification.title || notification.message /*&& auth.currentUser*/) {
                console.log(" notification type on foreground message ----> ", notification.type);
                this.handleLifeSherpaNotificationAlertForeground(notification, true);
              }
            });
        } else {
          console.log('No registration token available. Request permission to generate one.');
          this.registerDeviceForNotification();
          this.props.getHelpContactsWithChannel();
        }
      }).catch((error) => {
        const errorMsg = "Need notification permission!";
        NotificationManager.error(errorMsg);
        console.log('An error occurred while retrieving token. ', error); 
        this.props.getHelpContactsWithChannel(); 
        this.registerDeviceForNotification();
      }) 
    } catch (err) {
        console.error('Failed to initialize Firebase Messaging', err);
    }
    
  }

  turnOnNotification = async (messaging) => {
    const maxRetry = 3;
    for (let attempt = 1; attempt <= maxRetry; attempt++) {
      try {
        // Generate a new token
        const token = await getToken(messaging);
        console.log('Generated token for client:', token);
        return token;
      } catch (err) {
        if (attempt === maxRetry) {
          console.error("Max retries reached. Unable to generate token:", err);
        } else if ((err.code == "messaging/token-unsubscribe-failed") || (err.message.includes("Failed to execute 'subscribe' on 'PushManager'") || (err.message == `Failed to execute 'subscribe' on 'PushManager': Subscription failed - no active Service Worker`))) {
          console.warn("Retrying turnOnNotification due to subscription error:", attempt, err);
          await delay(2000); // Pause before retrying
        } else {
          console.error('turnOnNotification failed:', err);
          return;
        }
      }
    }
  };
    
  handleConfirmNotificationAlert = () => {
    this.getFirebaseMessagingToken();
    this.setState({ showEnableAlert: false});
  }

  handleCloseNotificationAlert = () => {
    this.props.getHelpContactsWithChannel();
    this.setState({ showEnableAlert: false});
    this.registerDeviceForNotification();
  }

  handleTwiolioNotification = (channelTitle, body, author ) => {
    this.props.networkActivity();
    if(this.checkUserInActive(channelTitle)) {
      // NotificationManager.info(body.replace(/\:/,'&').split('&')[1], body.replace(/\:/,'&').split('&')[0], 10000, () => {
      //   this.handleShowNotificationChat(channelTitle)
      // })
      let notificationTitle = author || body.replace(/\:/, '&').split('&')[1];
      let notificationOptions = {
        body: body.replace(/\:/, '&').split('&')[1],
        icon: AppConfig.lsIconLogo,
      };
      var notification = new Notification(notificationTitle, notificationOptions);
      let self = this;
      notification.onclick = function (event) {
        // event.preventDefault(); // prevent the browser from focusing the Notification's tab
        console.log(" Foreground notification clicked ---->");
        self.handleShowNotificationChat(channelTitle);
        notification.close();
      }
      // setTimeout(() => {
      //   notification.close();
      // }, 10000)
    }
  }

  handleShowNotificationChat = (channelTitle) => {
    console.log( " OnScreen notification selected -->")
    let {contactList , openChatPanel} = this.props;
    let notificationUser = contactList.find(user => user.conversation && user.conversation.channelState ? user.conversation.channelState.uniqueName  == channelTitle : channelTitle.includes(user.userId));
    console.log(" User who received notification --> ", notificationUser);
   
    if(openChatPanel) {
      this.props.hideChatPanel();
      console.log(" Chat panel closed...");
    } 
    if (notificationUser) {
      const selectedOrgId = (notificationUser.assignedOrganizations && Object.keys(notificationUser.assignedOrganizations)[0]);
      if(selectedOrgId) {
          localStorage.setItem('selectedOrgId', selectedOrgId);
      }
      this.props.showFullChatPanelForSelectedUser(notificationUser);
    } else {
      const auth_uid = localStorage.getItem("auth_uid");
      const userId = channelTitle.split("-").find(uid => uid !== auth_uid);
      this.props.getUserInfo(userId).then(result => {
        const {displayName, firstName, lastName, profileImageURL } = result;
        const tempContactUser = {userId, name: displayName || `${firstName || 'Client'} ${lastName || ''}`, profileImageURL: profileImageURL};
        this.props.showFullChatPanelForSelectedUser(tempContactUser);
      })
    }
  }

  checkUserInActive = (channelTitle) => {
    let {selectedChatUsers, selectedUser} = this.props;
    console.log(" selectedChatUsers List --> ", selectedChatUsers);
    console.log(" selectedUser --> ", selectedUser)
    let index = selectedChatUsers.findIndex(user => channelTitle.includes(user.userId));
    if(index >= 0) {
        console.log(" User chat active in minipanels....")
      return false;
    } else if(selectedUser && channelTitle.includes(selectedUser.userId)) {
      console.log(" User chat active ...");
      return false;
    } else {
      console.log(" User chat Inactive ...");
      return true;
    }
  }

  addPostMessageCall = () => {
    // check background notification data
    this.backgroundNotificationDataHandler();

    // adding listener
    window.addEventListener("message",  (event) => {
      if(event.data === "Background Notification") {
        console.log("Notification post message received ...");   
        this.backgroundNotificationDataHandler();
      }    
    });

  }

  backgroundNotificationDataHandler = () => {
    const backgroundNotificationData = localStorage.getItem("backgroundNotificationData");
    if(backgroundNotificationData) {
      localStorage.removeItem("backgroundNotificationData");
      const notificationData = JSON.parse(backgroundNotificationData) || {};
      console.log("backgroundNotificationData :", notificationData);
      if(notificationData.type == "twilioChat") {
        this.props.networkActivity();
        if(notificationData.channelTitle && this.checkUserInActive(notificationData.channelTitle)) {
            this.handleShowNotificationChat(notificationData.channelTitle)
        }
      } else if(notificationData.title || notificationData.message) {
        console.log(" notification type on post message ----> ", notificationData.type);
        this.handleLifeSherpaNotificationAlertForeground(notificationData) 
      }
    }
  }

  handleLifeSherpaNotificationAlertForeground = (notificationData, foreground) => {
    // if(notificationData.type === "7") {
    //   notificationData.message = notificationData.message.replace("please touch to complete verification", "");
    // }
    if(notificationData.type === "16") {
      this.handleOpenVidoeRoom(notificationData);
    } else if(notificationData.type === "24") {
      this.handleShowMissedCallConfirmation(notificationData);
    } else if(notificationData.type === "14") {
      const auth_uid = localStorage.getItem("auth_uid")
      const userId = localStorage.getItem('uid');
      const orgId = localStorage.getItem("selectedOrgId")
      const {selectedGroup, clientActivitiesType, selectedMember} = this.props
      const {groupId} = selectedMember || {};
      const activityType = clientActivitiesType === "Touchpoint" ? "Touchpoint" : null;
      this.props.showExecuteActivityPannel('')
      this.props.showExecuteActivityConfirmation(notificationData);
      if (activityType && auth_uid != userId) {
        this.props.getActivitiesListN({userId, orgId, groupId, filter:'', activityType});
      } else {
        this.props.getActivitiesList(auth_uid, "", selectedGroup);
      }
      
    } else if(notificationData.type === "7" && notificationData.needConfirmation === "true") {
      this.props.handleActivityCompletionConfirmation(notificationData);
    } else if(notificationData.type === "15") {
      this.props.showNotificationPopup(notificationData);
      this.props.getResourceList('','new', '')
      this.props.getcategories()
      this.props.getKeywords()
    } else if(notificationData.type === "25") {
      this.props.showNotificationPopup(notificationData);
    } else if (notificationData.type === "26") {
      return
    }

    this.handleLifeSherpaForegroundNotification(notificationData, foreground); 

  }

  handleLifeSherpaForegroundNotification = (notificationData, foreground) => {
    if(foreground) {
      let notificationTitle = notificationData.title || "LifeSherpa Notification";
      let notificationOptions = {
        body: notificationData.message || "",
        icon: AppConfig.lsIconLogo,
      };
      var notification = new Notification(notificationTitle, notificationOptions);
      let self = this;
      notification.onclick = function (event) {
        // event.preventDefault(); // prevent the browser from focusing the Notification's tab
        self.handleLifeSherpaNotificationClicked(notificationData)
        notification.close();
      }
      setTimeout(() => {
        notification.close();
      }, 10000)
    }
  }

  handleLifeSherpaNotificationClicked = (notificationData) => { 
    console.log(" notification type ----> ", notificationData.type)
    // switch(notificationData.type) {
    //   case "14":  // Start activity notification
    //   this.startActivityOnNotificationClicked(notificationData);
    //   break;
    // }
  }

  handleShowMissedCallConfirmation = (notificationData) => {
    if(notificationData && notificationData.group == "0") {
      this.props.showMissedCallConfirmation(notificationData);
    } else {
      this.handleLifeSherpaForegroundNotification(notificationData);
    }   
  }

  handleOpenVidoeRoom = (notificationData) => {
    let {showVideoRoom, showVideoCallConfirmationPanel} = this.props;
    if(!showVideoRoom && !showVideoCallConfirmationPanel) {
      this.props.showVideoCallConfirmation(notificationData)
    }
  }

  registerDeviceForNotification = (fcmToken) => {
    let payloadData = {appBuildNumber:"1316", //1177 // 154
    appVersion: "3.14.0",
    deviceId:fcmToken,
    deviceType:"Android 13.0.0", //iOS 15.1 //Android 10.0.0
    OS:"Android 13.0.0"}
    this.props.registerDeviceForNotification(payloadData)
  }

  browserDetection() {
    var ua= navigator.userAgent, tem, 
    M= ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if(/trident/i.test(M[1])){
        tem=  /\brv[ :]+(\d+)/g.exec(ua) || [];
        return 'IE '+(tem[1] || '');
    }
    if(M[1]=== 'Chrome'){
        tem= ua.match(/\b(OPR|Edge)\/(\d+)/);
        if(tem!= null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
    }
    M= M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
    if((tem= ua.match(/version\/(\d+)/i))!= null) M.splice(1, 1, tem[1]);
    return M.join(' ');
  }

  handleReload() {
    this.setState({reload: false})
    window.location.reload()
  }

  handleClose(){
    this.setState({reload: false})
  }

  getAlertMessage(browser) {
    const warningMessage = browser.includes("Safari") ? 
    `Currently, push notifications are not supported on ${browser}. If notification is required then you can use other browsers like Google Chrome, Firefox, Edge etc.` 
    : "Push notifications are not supported here. If you are using Private/Incognito window, please switch to a normal window to get push notifications."
    return warningMessage
  }


  render() {
    const {showEnableAlert, permissionStatus} = this.state;
    const { children, displayPushNotiAlert, displayOnce, screenThemeClass } = this.props;
    const browser = this.browserDetection()
    const warningMessage = this.getAlertMessage(browser)

    return (
        <React.Fragment>
            {children}
            <div className={screenThemeClass}>
            { displayPushNotiAlert && !displayOnce ?
              <SweetAlert
                warning
                btnSize="sm"
                customClass="warningText"
                confirmBtnText="OK"
                confirmBtnBsStyle="warning"
                title="Push Notification Alert!"
                onConfirm={() => this.props.hidePushNotiAlert(false)}
                >
                <div className="sweet-alert-text">{warningMessage}</div>
                </SweetAlert> : ""
            }
            {permissionStatus === 'default' && showEnableAlert ?
              <SweetAlert
                custom
                btnSize="sm"
                closeOnClickOutside={false}
                customClass="warningText"
                confirmBtnText="Yes"
                cancelBtnText="No"
                confirmBtnBsStyle="warning"
                cancelBtnBsStyle="default"
                title="Would you like to receive notifications from LifeSherpa?"
                showCancel
                onConfirm={() => this.handleConfirmNotificationAlert()}
                customIcon = {<div className='text-center'><NotificationsIcon style={{fontSize: '5.1875rem'}}/></div>}
                onCancel={() => this.handleCloseNotificationAlert()}
                >
                  <div className="sweet-alert-text">This will enable notifications in the browser.</div>
              </SweetAlert>
            : <></>
          }
          </div>
        </React.Fragment>
      )
      
  }
}

const mapStateToProps = ({ settings, Contacts, chatAppReducer, activitiesList, authUser, GroupList }) => {
	const { screenThemeClass  } = settings; 
  const { clientActivitiesType} = activitiesList
  const { selectedGroup } = GroupList

  const { displayPushNotiAlert, displayOnce } = authUser
  let {contactList, showVideoRoom, showVideoCallConfirmationPanel} = Contacts;
  const { selectedChatUsers, selectedUser , openChatPanel, selectedMember} = chatAppReducer;
	return {screenThemeClass, contactList, selectedChatUsers, selectedUser, openChatPanel,  showVideoRoom, showVideoCallConfirmationPanel, displayPushNotiAlert, displayOnce, selectedGroup, clientActivitiesType, selectedMember};
};

export default withRouter(connect(mapStateToProps, {
  getHelpContactsWithChannel, 
  hideChatPanel, 
  showFullChatPanelForSelectedUser, 
  registerDeviceForNotification,
  showVideoCallConfirmation,
  showMissedCallConfirmation,
  displayPushNotiAlertPopup,
  showExecuteActivityConfirmation,
  hidePushNotiAlert,
  networkActivity,
  handleActivityCompletionConfirmation,
  showNotificationPopup,
  getUserInfo,
  hideVideoRoomPanel,
  getActivitiesList,
  getResourceList,
  getcategories,
  getKeywords,
  getActivitiesListN,
  showExecuteActivityPannel
})(NotificationsHandler));
