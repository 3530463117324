/**
 * Touchpoint Widget Actions
 */

import {axiosClient, axiosAuthClient} from '../sherpaApi'

import {
    GET_GROUP_LIST,
    GET_SUCCESS_GROUP_LIST,
    GET_CLIENT_MEMBERS,
    GET_CLIENT_MEMBERS_SUCCESS,
    FETCH_USER_ORGANIZATIONS,
    FETCH_USER_ORGANIZATIONS_SUCCESS,
    GET_USER_BY_ID,
    HIDE_NOTIFICATION_LOADING,
    GET_USER_BY_ID_FAILED,
    GET_SUCCESS_USER_BY_ID,
    FETCH_PRIVACY_POLICIES_SUCCESS,
    GET_USER_INFO,
    HIDE_CLIENT_MEBERS_LOADING,
    GET_CLIENT_MEMBER_FAILED
} from './types';

export const getAllClientMembers = (groups, orgIds) => async (dispatch) => {
    try {
      const orgId = localStorage.getItem("orgId");
      const idToken = localStorage.getItem("idToken");
  
      // Prepare the filter group list
      const filtergroup = Array.isArray(groups)
        ? groups.map(group => ({ organization: group.orgId, groupId: group.id }))
        : [{ organization: orgId, groupId: groups }];
  
      console.log("Fetching group members for:", filtergroup);
  
      // Dispatch the action to start fetching members
      // dispatch({ type: GET_CLIENT_MEMBERS });
  
      // Construct the URL with groups and organizations
      let url = `/groups/members?groups=${encodeURIComponent(JSON.stringify(filtergroup))}`;
      if (orgIds && orgIds.length > 0) {
        url += `&organizations=${encodeURIComponent(JSON.stringify(orgIds))}`;
      }
  
      // Fetch data using axios
      const response = await axiosClient.get(url, {
        headers: { Authorization: `Bearer ${idToken}` },
      });
  
      const data = response.data || [];
  
      // Map the usersList efficiently
      const usersList = data.map(user => ({
        ...user,
        organization: user.groupsByOrg[0].organization,
        groupId: user.groupsByOrg[0].groups[0].groupId,
        groupsName: user.groupsByOrg[0].groups.map(group => group.groupName),
      }));
  
      console.log("Fetched usersList:", usersList);
  
      // Dispatch success action
      dispatch({ type: GET_CLIENT_MEMBERS_SUCCESS, payload: usersList });
  
    } catch (error) {
      // Handle errors
      const errorMsg = error.response?.data?.message || error.message;
      console.log("Error occurred while fetching group members:", errorMsg);
      dispatch({ type: GET_CLIENT_MEMBERS_SUCCESS, payload: []});
      dispatch({type: HIDE_CLIENT_MEBERS_LOADING})
    }
};

export const getWidgetUserById = (userId, idToken, widgetApp) => async (dispatch) => {
    try {
      const url = `/users/${userId}`;
      console.log(`Fetching user details for userId: ${userId}...`);
      
      // Dispatching initial actions
      dispatch({ type: GET_USER_BY_ID });
      dispatch({ type: HIDE_NOTIFICATION_LOADING });
      dispatch({ type: GET_CLIENT_MEMBERS });
  
      // Fetch user details
      const response = await axiosClient.get(url, {
        headers: {
          Authorization: `Bearer ${idToken}`,
        },
      });
  
      console.log(`%cFetched user details for userId: ${userId}`, "color:green");
      
      // Handling successful response
      const userData = response.data;
      dispatch({ type: GET_SUCCESS_USER_BY_ID, payload: userData });
  
      // Check if response is successful
      if (response.status === 200) {
        const organizationId = userData.organization;
        
        // Store user organization info in localStorage
        localStorage.setItem("organization", organizationId);
        localStorage.setItem("selectedOrgId", organizationId);
        localStorage.setItem("orgId", organizationId);
        localStorage.setItem("pokeFormId", userData.pokeFormId);
  
        console.log("App widget ->", widgetApp);
  
        // Create user object and dispatch user info
        const user = {
          uid: userId,
          name: userData.displayName || `${userData.firstName || "Display"} ${userData.lastName || "Name"}`,
          picture: userData.profileImageURL,
          ...userData
        };
  
        dispatch({ type: GET_USER_INFO, payload: user });
        dispatch({ type: FETCH_PRIVACY_POLICIES_SUCCESS, payload: { status: true } });
  
        // Dispatch group members fetch if the user is a 'Parent'
        if (userData.role === 'Parent') {
          dispatch(getAllClientMembersWidget());
        }
      }
    } catch (error) {
      // Error handling
      dispatch({ type: GET_USER_BY_ID_FAILED });
      dispatch({type: HIDE_CLIENT_MEBERS_LOADING})
      const errorMsg = error.response?.data?.message || error.message;
      console.log(`%cError occurred while fetching user details for userId: ${userId}`, "color:red", errorMsg, error);
    }
};
  

export const getAllClientMembersWidget = () => async (dispatch) => {
    try {
      const userId = localStorage.getItem("auth_uid");
      const idToken = localStorage.getItem("idToken");
  
      const headers = { Authorization: `Bearer ${idToken}` };
      // Start fetching user organizations and groups concurrently
      dispatch({ type: FETCH_USER_ORGANIZATIONS });
      dispatch({ type: GET_GROUP_LIST });
  
      const [orgResponse, groupsResponse] = await Promise.all([
        axiosClient.get(`/users/${userId}/organizations`, { headers }),
        axiosClient.get(`/users/${userId}/admin-groups`, { headers }),
      ]);
  
      // Handling organizations response
      const orgData = orgResponse.data || [];
      const orgIds = orgData.map((org) => org.id);
      localStorage.setItem("orgIds", orgIds);
  
      dispatch({ type: FETCH_USER_ORGANIZATIONS_SUCCESS, payload: orgData });
  
      // Handling groups response
      const groupData = groupsResponse.data || [];
      dispatch({ type: GET_SUCCESS_GROUP_LIST, payload: groupData });
  
      // Fetching all group members
      dispatch(getAllClientMembers(groupData, orgIds));
  
    } catch (error) {
      console.error("Error fetching group members:", error);
      dispatch({type: HIDE_CLIENT_MEBERS_LOADING})
      // Handle failure cases (could dispatch failure actions, show messages, etc.)
    }
};

export const getEastBayAllClientMembers = (secret_key) => async(dispatch) => {
  try {
    const headers = { "secret_key": secret_key};
    dispatch({type: GET_CLIENT_MEMBERS})
    // Start fetching user organizations and groups concurrently
    const response = await axiosAuthClient.get(`webhooks/v1/east-bay/groups/members`, { headers })
    const data = response.data || [];
  
    // Map the usersList efficiently
    const usersList = data.map(user => ({
      ...user,
      organization: user.groupsByOrg[0].organization,
      groupId: user.groupsByOrg[0].groups[0].groupId,
      groupsName: user.groupsByOrg[0].groups.map(group => group.groupName),
    }));

    console.log("Fetched usersList:", usersList);
    // Dispatch success action
    dispatch({ type: GET_CLIENT_MEMBERS_SUCCESS, payload: usersList });
  } catch (error) {
    console.error("Error fetching group members:", error);
    const errorMsg = error.response ? error.response.data.message : error.message;
    dispatch({type: HIDE_CLIENT_MEBERS_LOADING})
    dispatch({type: GET_CLIENT_MEMBER_FAILED, payload: errorMsg})
  }
};

export const clearClientErrorResponse = () => (dispatch) => {
  dispatch({type: GET_CLIENT_MEMBER_FAILED})
}