/**
 * Workflow Actions
 */

import {axiosClient} from '../sherpaApi'

import {
    GET_WORKFLOW_EXECUTION_HISTORY,
    GET_SUCCESS_WORKFLOW_EXECUTION_HISTORY,
    ASSIGN_USER_TO_PENDING_WORKFLOW_EXECUTION,
    ASSIGN_SUCCESS_USER_TO_PNDING_WORKFLOW_EXECUTION,
    ASSIGN_USER_TO_PNDING_WORKFLOW_EXECUTION_FAILED,
    START_WORKFLOW_FAILED,
    GET_WORKFLOW_LIST,
    GET_SUCCESS_WORKFLOW_LIST,
    START_WORKFLOW,
    START_SUCCESS_WORKFLOW,
    GET_ASSIGNABLE_WORKFLOW_USER,
    GET_SUCCESS_ASSIGNABLE_WORKFLOW_USER,
    SHOW_WORKFLOW_LIST_LOADING,
    HIDE_WORKFLOW_LIST_LOADING,
    SHOW_START_WORKFLOW_PANEL,
    HIDE_START_WORKFLOW_PANEL,
    GET_USER_LIST_NOT_ACTIVE_IN_WORKFLOW,
    GET_SUCCESSS_USER_LIST_NOT_ACTIVE_IN_WORKFLOW,
    SHOW_WORKFLOW_HISTORY_PANEL,
    SET_SELECTED_WORKFLOW_ITEM,
    HANDLE_USER_WORKFLOW_PANEL,
    GET_USER_PENDING_TASK_WORKFLOW_LIST,
    GET_SUCCESS_USER_PENDING_TASK_WORKFLOW_LIST,
    HIDE_WORKFLOW_LOADING_BY_USER,
    CLEAR_ASSIGN_SUCCESS_USER_TO_PNDING_WORKFLOW_EXECUTION,
    CLEAR_START_SUCCESS_WORKFLOW,
    CLEAR_WORKFLOW_ERROR_RESPONSE,
    HIDE_WORKFLOW_NOTICES,
    GET_WORKFLOW_NOTICES,
    GET_SUCCESS_WORKFLOW_NOTICES,
    CANCEL_WORKFLOW_INSTANCE,
    CANCEL_SUCCESS_WORKFLOW_INSTANCE,
    SKIP_WORKFLOW_INSTANCE_TASKS,
    SKIP_SUCCESS_WORKFLOW_INSTANCE_TASKS,
    HIDE_WORKFLOW_CANCEL_LOADING,
    HIDE_WORKFLOW_SKIP_TASK_LOADING,
    OPEN_CANCEL_WORKFLOW_CONFIRMATION,
    OPEN_SKIP_WORKFLOW_TASK,
    GET_WORKFLOW_EXECUTION_DEATIL,
    GET_SUCCESS_WORKFLOW_EXECUTION_DEATIL,
    GET_SUB_WORKFLOW_EXECUTION_DEATIL,
    GET_SUCCESS_SUB_WORKFLOW_EXECUTION_DEATIL,
    SET_SELECTED_SUB_WORKFLOW_ITEM,
    HIDE_WORKFLOW_HISTROY_DETAIL_LOADING,
    CLEAR_WORKFLOW_HISTORY_DETAIL_DATA,
    GET_WORKFLOW_CHART_DATA,
    GET_SUCCESS_WORKFLOW_CHART_DATA,
    HIDE_WORKFLOW_WORKFLOW_CHART_DATA_LOADING,
    SHOW_EXECUTION_PENDING_ROLE_POPUP_PANEL,
    HANDLE_SELECT_WORKFLOW_STATUS_CATEGORY
} from './types';
import { NotificationManager } from 'react-notifications';
import {getFilteredHistoryList} from '../helpers/helpers'

export const getWorkflowList = (orgIds) => (dispatch) => {
    const idToken = localStorage.getItem('idToken')
    let url = ""
    if (orgIds && orgIds.length) {
        url = `/workflows?organizations=${encodeURIComponent(JSON.stringify(orgIds))}` 
    } else {
        url = `/workflows` 
    }
    dispatch({type: GET_WORKFLOW_LIST})
    axiosClient.get(url, {headers: {
        'Authorization': `Bearer ${idToken}`
    }
    }).then((response) => {
        console.log("workflow api response ----.",response.data);
        console.log("%c Fetched user workflow list for orgId:"+orgIds,"color:green");
        dispatch({ type: GET_SUCCESS_WORKFLOW_LIST, payload: response.data });        
    }).catch(error => {
        const errorMsg = error.response ? error.response.data.message : error.message;
        console.log("%c Error Occured while fetching user workflow list for orgId:"+orgIds,"color:red", errorMsg);
        NotificationManager.error(errorMsg)
        dispatch({type:HIDE_WORKFLOW_LIST_LOADING});
    })
}

export const startWorkflow = ({orgId, workflowId, data, ignoreMaxWorkflowsLimit}) => (dispatch) => {
    const idToken = localStorage.getItem('idToken')
    console.log(" Fetching user workflow list for orgId:"+orgId+"......");
    let url = `/workflows/${workflowId}/executions?organization=${orgId}`;
    if(ignoreMaxWorkflowsLimit) { 
        url = `${url}&ignoreMaxWorkflowsLimit=true`;
    }
    dispatch({type: START_WORKFLOW})
    axiosClient.post(url, data, {headers: {
        'Authorization': `Bearer ${idToken}`
    }
    }).then((response) => {
       const message = response.data && response.data.message ? response.data.message  : 'New workflow execution created successfully';
        NotificationManager.success(message);     
        dispatch({ type: START_SUCCESS_WORKFLOW, payload: {...response.data, orgId, workflowId, data} });  
    }).catch(error => {
        const errorResponse = (error.response && error.response.data) || error;
        console.log("%c Failed to start workflow:","color:red", errorResponse);
        if(errorResponse.errorCode === "E1422" || errorResponse.errorCode === "E1421") {
            dispatch({ type: START_WORKFLOW_FAILED, payload: errorResponse});
        } else {
            const errorMsg = errorResponse.message;
            NotificationManager.error(errorMsg)
            dispatch({type:HIDE_WORKFLOW_LIST_LOADING});
        }  
    })
} 

export const showWorkflowLoading = () => (dispatch) => {
    dispatch({type: SHOW_WORKFLOW_LIST_LOADING})
}

export const hideWorkflowLoading = () => (dispatch) => {
    dispatch({type: HIDE_WORKFLOW_LIST_LOADING})
}

export const getRealtimeWorkflowDetail = ({selectedWorkflow, workflowlist}) => (dispatch) =>{
    
    if (workflowlist && workflowlist.length > 0 ) {
        workflowlist.map(workflow => {
            if ((workflow.id === selectedWorkflow.id) && workflow.state === "inProgress"){
                workflow.state = ""
            } else if (workflow.id === selectedWorkflow.id)  {
                workflow.state = "inProgress"
            }
        })
    }
    dispatch({ type: GET_SUCCESS_WORKFLOW_LIST, payload: workflowlist });   
}

export const getAssignableWorkflowUsers = ({workflowId, orgId, executionId}) => (dispatch) =>{
    const idToken = localStorage.getItem('idToken')
    console.log(" Fetching user workflow user for role:......");
    let url = `/workflows/${workflowId}/users?organization=${orgId}`;
    if(executionId)  {
        url = `${url}&executionId=${executionId}`;
    }

    dispatch({type: GET_ASSIGNABLE_WORKFLOW_USER})
    axiosClient.get(url, {headers: {
        'Authorization': `Bearer ${idToken}`
    }
    }).then((response) => {
        console.log("workflow api response ----.",response.data);
        dispatch({ type: GET_SUCCESS_ASSIGNABLE_WORKFLOW_USER, payload: response.data });        
    }).catch(error => {
        const errorMsg = error.response ? error.response.data.message : error.message;
        console.log("%c Error Occured while fetching user workflow user for role:","color:red", errorMsg);
        NotificationManager.error(errorMsg)
        dispatch({type:HIDE_WORKFLOW_LIST_LOADING});
    })
}

export const showStartWorkflowPanel = ({workflow, openWorkflowPendingList}) => (dispatch) =>{
    dispatch({ type: SHOW_START_WORKFLOW_PANEL, payload: {workflow, openWorkflowPendingList}});   
}

export const hideStartWorkflowPanel = () => (dispatch) =>{
    dispatch({ type: HIDE_START_WORKFLOW_PANEL });   
}

export const getWorkflowHistory = ({workflowId, orgId, allHistoryData, executionId, selectedWorkflowStatus, loadFirstDetail = true}) => (dispatch) => {
    const idToken = localStorage.getItem('idToken')
    // console.log(" Fetching user workflow user for role:......");
    const url = `/workflows/${workflowId}/executions?organization=${orgId}${allHistoryData ? "" : "&roleAssignmentPending=true"}` 
    dispatch({type: GET_WORKFLOW_EXECUTION_HISTORY})
    axiosClient.get(url, {headers: {
        'Authorization': `Bearer ${idToken}`
    }
    }).then((response) => {
        // console.log("workflow api response ----.",response.data);
        const historyList = response.data;
        if (executionId) {
            dispatch(getWorkflowHistoryDetail({workflowId, orgId, executionId}))     
        } else if(historyList[0] && loadFirstDetail) {
            dispatch(getDefaultHistoryDetail({workflowId, orgId, historyList, selectedStatus: selectedWorkflowStatus}))
            
        }
        dispatch({ type: GET_SUCCESS_WORKFLOW_EXECUTION_HISTORY, payload: historyList });        
    }).catch(error => {
        const errorMsg = error.response ? error.response.data.message : error.message;
        console.log("%c Error Occured while fetching user workflow user for role:","color:red", errorMsg);
        NotificationManager.error(errorMsg)
        dispatch({type:HIDE_WORKFLOW_LIST_LOADING});
    })
}

export const getDefaultHistoryDetail = ({workflowId, orgId, userId, historyList, selectedStatus}) => (dispatch) => {
    let historyId;
    let historyStatus = "open";
    if(selectedStatus) {
        let filteredList = getFilteredHistoryList(historyList, selectedStatus);
        historyId = filteredList[0] && filteredList[0].id;
    } else {
        let filteredList = getFilteredHistoryList(historyList, historyStatus);
        if(!filteredList.length) {
            historyStatus = "completed";
            filteredList = getFilteredHistoryList(historyList, historyStatus);
        }
        if(!filteredList.length) {
            historyStatus = "cancelled";
            filteredList = getFilteredHistoryList(historyList, historyStatus);
        }
        historyId = filteredList[0] && filteredList[0].id;
    }
    if(historyId) {
        if(userId) {
            dispatch(getUserWorkflowHistoryDetail({workflowId, orgId, userId, executionId: historyId}));
        } else {
            dispatch(getWorkflowHistoryDetail({workflowId, orgId, executionId: historyId}));
        }  
    }
    if(historyStatus != "open" ) {
        dispatch(handleSelectWorkflowStatusCategory(historyStatus));
    }
}

export const assignUserToPendingWorkflow = ({orgId, workflowId, executionId, userId, data, ignoreMaxWorkflowsLimit, pendingRoleOnHistory, isSubWorkflowOpened}) => (dispatch) => {
    const idToken = localStorage.getItem('idToken')
    console.log(" Fetching user workflow user for role:......");
    let url = `/workflows/${workflowId}/executions/${executionId}/assign-users?organization=${orgId}` 
    if(ignoreMaxWorkflowsLimit) { 
        url = `${url}&ignoreMaxWorkflowsLimit=true`;
    }
    dispatch({type: ASSIGN_USER_TO_PENDING_WORKFLOW_EXECUTION})
    axiosClient.post(url, data, {headers: {
        'Authorization': `Bearer ${idToken}`
    }
    }).then((response) => {
        console.log("workflow api response ----.",response.data);
        dispatch({ type: ASSIGN_SUCCESS_USER_TO_PNDING_WORKFLOW_EXECUTION, payload: {...response.data, orgId, workflowId, executionId, data} });
        if(pendingRoleOnHistory) {
            if(isSubWorkflowOpened) {
                if (userId) {
                    dispatch(getUserSubWorkflowHistoryDetail({subWorkflowId: workflowId, userId, orgId, executionId}))
                } else {
                    dispatch(getSubWorkflowHistoryDetail({subWorkflowId: workflowId, orgId, executionId})) 
                }
            } else {
                if (userId) {
                    dispatch(getUserWorkflowHistoryDetail({workflowId, userId, orgId, executionId}))
                } else {
                    dispatch(getWorkflowHistoryDetail({workflowId, orgId, executionId})) 
                }
            } 
            dispatch({type: SHOW_EXECUTION_PENDING_ROLE_POPUP_PANEL, payload: false})
        } else {
            dispatch({type: HIDE_START_WORKFLOW_PANEL}) 
        }
        NotificationManager.success(response.data.message)       
    }).catch(error => {
        const errorResponse = (error.response && error.response.data) || error;
        console.log("%c Failed to assign users to workflow:","color:red", errorResponse);
        if(errorResponse.errorCode === "E1422" || errorResponse.errorCode === "E1421") {
            dispatch({ type: ASSIGN_USER_TO_PNDING_WORKFLOW_EXECUTION_FAILED, payload: errorResponse});
        } else {
            const errorMsg = errorResponse.message;
            NotificationManager.error(errorMsg)
            dispatch({type:HIDE_WORKFLOW_LIST_LOADING});
        }        
       
    })
}

export const getUsersNotActiveOnWorkflows = (orgId) => (dispatch) => {
    const idToken = localStorage.getItem('idToken')
    console.log(" Fetching users who have no active workflow:......");
    // dispatch({ type: GET_SUCCESSS_USER_LIST_NOT_ACTIVE_IN_WORKFLOW, payload: {count: 20} }); 
    const url = `/workflows/applicants-without-active-workflows`;
    dispatch({type: GET_USER_LIST_NOT_ACTIVE_IN_WORKFLOW})
    axiosClient.get(url, {headers: {
        'Authorization': `Bearer ${idToken}`
    }
    }).then((response) => {
        console.log("users who have no active workflow response ----.",response.data);
        dispatch({ type: GET_SUCCESSS_USER_LIST_NOT_ACTIVE_IN_WORKFLOW, payload: response.data });        
    }).catch(error => {
        const errorMsg = error.response ? error.response.data.message : error.message;
        console.log("%c Error Occured while fetching user workflow user for role:","color:red", errorMsg);
        NotificationManager.error(errorMsg)
        dispatch({type:HIDE_WORKFLOW_LIST_LOADING});
    })
}

export const openWorkflowHistoryPanel = (selectedWorkflow, openPanel, selectedWorkflowStatus) => (dispatch) => {
    dispatch({type: SHOW_WORKFLOW_HISTORY_PANEL ,payload: {selectedWorkflow, openPanel, selectedWorkflowStatus}})
}

export const setSelectedWorkflowItem = (selectedItem) => (dispatch) => {
    dispatch({type: SET_SELECTED_WORKFLOW_ITEM, payload: selectedItem})
}

export const setSelectedSubWorkflow = (selectedSubWorkflow) => (dispatch) => {
    dispatch({type: SET_SELECTED_SUB_WORKFLOW_ITEM, payload: selectedSubWorkflow})
}

export const handleUserWorkflowPanel = (show, userId, orgId) => (dispatch) => {
    dispatch({ type: HANDLE_USER_WORKFLOW_PANEL, payload: { userId: userId, show: show, orgId: orgId } });
}

export const getUserPendingWorkflowList = ({userId, orgId}) => (dispatch) => {
    const idToken = localStorage.getItem('idToken')
    console.log(" Fetching workflows having pending tasks:......");
    const url = `/workflows/users/${userId}/pending-tasks` 
    dispatch({type: GET_USER_PENDING_TASK_WORKFLOW_LIST})
    axiosClient.get(url, {headers: {
        'Authorization': `Bearer ${idToken}`
    }
    }).then((response) => {
        console.log("workflows having pending tasks response ----.",response.data);
        let workflowPendingList = response.data
        let count = 0
        if (workflowPendingList && workflowPendingList.length > 0) {
            workflowPendingList.map(item => {
                if (item.tasks.length > 0) {
                    count += item.tasks.length
                }
            })
        }
        workflowPendingList["count"] = count
        dispatch({type: GET_SUCCESS_USER_PENDING_TASK_WORKFLOW_LIST, payload: workflowPendingList})        
    }).catch(error => {
        const errorMsg = error.response ? error.response.data.message : error.message;
        console.log("%c Error Occured while fetching workflows having pending tasks for role:","color:red", errorMsg);
        NotificationManager.error(errorMsg)
        dispatch({type:HIDE_WORKFLOW_LOADING_BY_USER});
    })    
}

 
export const clearAssignUserResponseData = () => (dispatch) => {
    dispatch({type: CLEAR_ASSIGN_SUCCESS_USER_TO_PNDING_WORKFLOW_EXECUTION})
}

export const clearWorkflowExcResponseData = () => (dispatch) => {
    dispatch({type: CLEAR_START_SUCCESS_WORKFLOW})
}

export const clearWorkflowErrorResponse = () => (dispatch) => {
    dispatch({type: CLEAR_WORKFLOW_ERROR_RESPONSE})
}

export const getWorkflowNotices = (orgId) => (dispatch) => {
    const idToken = localStorage.getItem('idToken')
    console.log(" Fetching workflows Notices:......");
    const url = `/workflows/notices?organization=${orgId}` 
    dispatch({type: GET_WORKFLOW_NOTICES})
    axiosClient.get(url, {headers: {
        'Authorization': `Bearer ${idToken}`
    }
    }).then((response) => {
        console.log("Workflows Notices response ----.",response.data);
        dispatch({type: GET_SUCCESS_WORKFLOW_NOTICES, payload: response.data})        
    }).catch(error => {
        const errorMsg = error.response ? error.response.data.message : error.message;
        console.log("%c Error Occured while fetching Workflows Notices for role:","color:red", errorMsg);
        NotificationManager.error(errorMsg)
        dispatch({type:HIDE_WORKFLOW_NOTICES});
    })    
}

export const cancelWorkflowInstance = (workflowId, executionId, orgId, userId) => (dispatch) => {
    const idToken = localStorage.getItem('idToken')
    console.log(" Cancelling workflow instance:......");
    const url = `/workflows/${workflowId}/executions/${executionId}/cancel?organization=${orgId}` 
    dispatch({type: CANCEL_WORKFLOW_INSTANCE})
    axiosClient.post(url, null, {headers: {
        'Authorization': `Bearer ${idToken}`
    }
    }).then((response) => {
        console.log("Workflow Instance cancelled response ----.",response.data);
        dispatch({type: CANCEL_SUCCESS_WORKFLOW_INSTANCE, payload: response.data})  
        if (userId) {
            dispatch(getUserWorkflowHistory({workflowId, userId, orgId}))
        } else {
            dispatch(getWorkflowHistory({ workflowId, orgId, allHistoryData: true })) 
        }
        dispatch(setSelectedWorkflowItem())
    }).catch(error => {
        const errorMsg = error.response ? error.response.data.message : error.message;
        console.log("%c Error Occured while Workflow Instance cancelling for role:","color:red", errorMsg);
        NotificationManager.error(errorMsg)
        dispatch({type:HIDE_WORKFLOW_CANCEL_LOADING});
    })    
}

export const skipWorkflowInstanceTasks = (workflowId, executionId, workflowTasks, orgId, userId) => (dispatch) => {
    const idToken = localStorage.getItem('idToken')
    console.log(" Skipping workflow Instance tasks:......");
    const url = `/workflows/${workflowId}/executions/${executionId}/skip/tasks?organization=${orgId}` 
    dispatch({type: SKIP_WORKFLOW_INSTANCE_TASKS})
    axiosClient.post(url, workflowTasks, {headers: {
        'Authorization': `Bearer ${idToken}`
    }
    }).then((response) => {
        console.log("Skipped workflow instance tasks response ----.",response.data);
        dispatch({type: SKIP_SUCCESS_WORKFLOW_INSTANCE_TASKS, payload: response.data}) 
        if (userId) {
            dispatch(getUserWorkflowHistory({workflowId, userId, orgId, executionId}))
        } else {
            dispatch(getWorkflowHistory({ workflowId, orgId, allHistoryData: true, executionId }))   
        }
    }).catch(error => {
        const errorMsg = error.response ? error.response.data.message : error.message;
        console.log("%c Error Occured while Skipping workflow instance tasks for role:","color:red", errorMsg);
        const messageString = errorMsg == "Task result not found" ? "This task has dependencies in Client Alert tasks. If you want to skip this task, please also select Client Alert tasks to skip." : errorMsg
        NotificationManager.error(messageString)
        dispatch({type:HIDE_WORKFLOW_SKIP_TASK_LOADING});
    })    
}

export const openCancelWorkflowConfirmation = (openCWPPopup) => (dispatch) => {
    dispatch({type: OPEN_CANCEL_WORKFLOW_CONFIRMATION, payload: openCWPPopup})
}

export const openSkipWorkflow = (openSWTPoup) => (dispatch) => {
    dispatch({type: OPEN_SKIP_WORKFLOW_TASK, payload: openSWTPoup })
}

export const getUserWorkflowList = (userId, orgIds) => (dispatch) => {
    const idToken = localStorage.getItem('idToken')
    let url = ""
    if (orgIds && orgIds.length) {
        url = `/workflows/users/${userId}?organizations=${encodeURIComponent(JSON.stringify(orgIds))}`
    } else {
        url = `/workflows/users/${userId}`
    }
      
    dispatch({type: GET_WORKFLOW_LIST})
    axiosClient.get(url, {headers: {
        'Authorization': `Bearer ${idToken}`
    }
    }).then((response) => {
        console.log("workflow api response ----.",response.data);
        console.log("%c Fetched user workflow list for orgId:"+orgIds,"color:green");
        dispatch({ type: GET_SUCCESS_WORKFLOW_LIST, payload: response.data });        
    }).catch(error => {
        const errorMsg = error.response ? error.response.data.message : error.message;
        console.log("%c Error Occured while fetching user workflow list for orgId:"+orgIds,"color:red", errorMsg);
        NotificationManager.error(errorMsg)
        dispatch({type:HIDE_WORKFLOW_LIST_LOADING});
    })
}

export const getUserWorkflowHistory = ({workflowId, userId, orgId, executionId, selectedWorkflowStatus}) => (dispatch) => {
    const idToken = localStorage.getItem('idToken')
    // console.log(" Fetching user workflow user for role:......");
    const url = `/workflows/${workflowId}/users/${userId}/executions?organization=${orgId}` 
    dispatch({type: GET_WORKFLOW_EXECUTION_HISTORY})
    axiosClient.get(url, {headers: {
        'Authorization': `Bearer ${idToken}`
    }
    }).then((response) => {
        // console.log("workflow api response ----.",response.data);
        let historyList = response.data || [];
        if (executionId) {
            dispatch(getUserWorkflowHistoryDetail({workflowId, userId, orgId, executionId}))
        } else if(historyList[0]) {
            dispatch(getDefaultHistoryDetail({workflowId, orgId, userId, historyList, selectedStatus: selectedWorkflowStatus}))
        }
        dispatch({ type: GET_SUCCESS_WORKFLOW_EXECUTION_HISTORY, payload: historyList });        
    }).catch(error => {
        const errorMsg = error.response ? error.response.data.message : error.message;
        console.log("%c Error Occured while fetching user workflow user for role:","color:red", errorMsg);
        NotificationManager.error(errorMsg)
        dispatch({type:HIDE_WORKFLOW_LIST_LOADING});
    })
}

export const setUserSelectedWorkflowItem = (userSelectedItem) => (dispatch) => {
    dispatch({type: SET_SELECTED_WORKFLOW_ITEM, payload: userSelectedItem})
}

export const getWorkflowHistoryDetail = ({workflowId, orgId, executionId}) => (dispatch) => {
    const idToken = localStorage.getItem('idToken')
    console.log(" Fetching user workflow execution detail for role:......");
    const url = `/workflows/${workflowId}/executions/${executionId}?organization=${orgId}` 
    dispatch({type: GET_WORKFLOW_EXECUTION_DEATIL})
    axiosClient.get(url, {headers: {
        'Authorization': `Bearer ${idToken}`
    }
    }).then((response) => {
        console.log("workflow api response ----.",response.data);
        dispatch({ type: GET_SUCCESS_WORKFLOW_EXECUTION_DEATIL, payload: response.data });  
        dispatch(setSelectedWorkflowItem(response.data))      
    }).catch(error => {
        const errorMsg = error.response ? error.response.data.message : error.message;
        console.log("%c Error Occured while fetching user workflow execution detail for role:","color:red", errorMsg);
        NotificationManager.error(errorMsg)
        dispatch({type:HIDE_WORKFLOW_HISTROY_DETAIL_LOADING});
    })
}

export const getSubWorkflowHistoryDetail = ({subWorkflowId, orgId, executionId}) => (dispatch) => {
    const idToken = localStorage.getItem('idToken')
    console.log(" Fetching user workflow execution detail for role:......");
    const url = `/workflows/${subWorkflowId}/executions/${executionId}?organization=${orgId}` 
    dispatch({type: GET_SUB_WORKFLOW_EXECUTION_DEATIL})
    axiosClient.get(url, {headers: {
        'Authorization': `Bearer ${idToken}`
    }
    }).then((response) => {
        console.log("workflow api response ----.",response.data);
        dispatch({ type: GET_SUCCESS_SUB_WORKFLOW_EXECUTION_DEATIL, payload: response.data });  
        dispatch(setSelectedSubWorkflow(response.data))      
    }).catch(error => {
        const errorMsg = error.response ? error.response.data.message : error.message;
        console.log("%c Error Occured while fetching user workflow execution detail for role:","color:red", errorMsg);
        NotificationManager.error(errorMsg)
        dispatch({type:HIDE_WORKFLOW_HISTROY_DETAIL_LOADING});
    })
}

export const getUserWorkflowHistoryDetail = ({workflowId, userId, orgId, executionId}) => (dispatch) => {
    const idToken = localStorage.getItem('idToken')
    console.log(" Fetching user workflow execution detail for role:......");
    const url = `/workflows/${workflowId}/users/${userId}/executions/${executionId}?organization=${orgId}` 
    dispatch({type: GET_WORKFLOW_EXECUTION_DEATIL})
    axiosClient.get(url, {headers: {
        'Authorization': `Bearer ${idToken}`
    }
    }).then((response) => {
        console.log("workflow api response ----.",response.data);
        dispatch({ type: GET_SUCCESS_WORKFLOW_EXECUTION_DEATIL, payload: response.data }); 
        dispatch(setSelectedWorkflowItem(response.data))        
    }).catch(error => {
        const errorMsg = error.response ? error.response.data.message : error.message;
        console.log("%c Error Occured while fetching user  workflow execution detail for role:","color:red", errorMsg);
        NotificationManager.error(errorMsg)
        dispatch({type:HIDE_WORKFLOW_HISTROY_DETAIL_LOADING});
    })
}

export const getUserSubWorkflowHistoryDetail = ({subWorkflowId, userId, orgId, executionId}) => (dispatch) => {
    const idToken = localStorage.getItem('idToken')
    console.log(" Fetching user workflow execution detail for role:......");
    const url = `/workflows/${subWorkflowId}/users/${userId}/executions/${executionId}?organization=${orgId}` 
    dispatch({type: GET_SUB_WORKFLOW_EXECUTION_DEATIL})
    axiosClient.get(url, {headers: {
        'Authorization': `Bearer ${idToken}`
    }
    }).then((response) => {
        console.log("workflow api response ----.",response.data);
        dispatch({ type: GET_SUCCESS_SUB_WORKFLOW_EXECUTION_DEATIL, payload: response.data }); 
        dispatch(setSelectedSubWorkflow(response.data))        
    }).catch(error => {
        const errorMsg = error.response ? error.response.data.message : error.message;
        console.log("%c Error Occured while fetching user  workflow execution detail for role:","color:red", errorMsg);
        NotificationManager.error(errorMsg)
        dispatch({type:HIDE_WORKFLOW_HISTROY_DETAIL_LOADING});
    })
}

export const removeWorkflowDetailData = () => (dispatch) => {
    dispatch({type: CLEAR_WORKFLOW_HISTORY_DETAIL_DATA})
}

export const getWorkflowChartData = (userId, filterData, orgIds) => (dispatch) => {
    const idToken = localStorage.getItem('idToken')
    const orgId = localStorage.getItem("orgId")
    console.log(" Fetching workflow chart data for role:......");
    let url = ""

    if (orgIds && orgIds.length) {
        url = `/reports/workflows/${userId}/charts/WorkflowExecutionSummary?orgId=${orgId}&organizations=${encodeURIComponent(JSON.stringify(orgIds))}&fromDate=${encodeURIComponent(filterData.fromDate)}&toDate=${encodeURIComponent(filterData.toDate)}`
    } else {
        url = `/reports/workflows/${userId}/charts/WorkflowExecutionSummary?orgId=${orgId}&fromDate=${encodeURIComponent(filterData.fromDate)}&toDate=${encodeURIComponent(filterData.toDate)}`
    }
      
    dispatch({type: GET_WORKFLOW_CHART_DATA})
    axiosClient.get(url, {headers: {
        'Authorization': `Bearer ${idToken}`
    }
    }).then((response) => {
        console.log("workflow chart data api response ----.",response.data);
        dispatch({ type: GET_SUCCESS_WORKFLOW_CHART_DATA, payload: response.data });        
    }).catch(error => {
        const errorMsg = error.response ? error.response.data.message : error.message;
        console.log("%c Error Occured while fetching workflow chart data for role:","color:red", errorMsg);
        NotificationManager.error(errorMsg)
        dispatch({type:HIDE_WORKFLOW_WORKFLOW_CHART_DATA_LOADING});
    })
}

export const showExecutionHistoryPendingRolePopup = (data) => (dispatch) => {
    dispatch({type: SHOW_EXECUTION_PENDING_ROLE_POPUP_PANEL, payload: data})
}

export const handleSelectWorkflowStatusCategory = (data) => (dispatch) => {
    dispatch({type: HANDLE_SELECT_WORKFLOW_STATUS_CATEGORY, payload: data})
}