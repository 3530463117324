import {
    GET_CLIENT_MEMBERS,
    GET_CLIENT_MEMBERS_SUCCESS,
    HIDE_CLIENT_MEBERS_LOADING,
    GET_CLIENT_MEMBER_FAILED

} from '../actions/types';

const INIT_STATE = {
    clientsData:null,
    loading:false,
    errorResponseMessage: null
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_CLIENT_MEMBERS:
            return { ...state, clientsData: null, loading: true, errorResponseMessage: null };
        case GET_CLIENT_MEMBERS_SUCCESS:
            return {...state, clientsData: action.payload, loading:false}
        case HIDE_CLIENT_MEBERS_LOADING:
            return { ...state, clientsData: null, loading: false};
        case GET_CLIENT_MEMBER_FAILED:
            return {...state, loading: false, errorResponseMessage: action.payload}
        default: 
            return { ...state };
    }
}