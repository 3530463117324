/**
 * Chat Sidebar
 */
import React, { Component } from 'react';
import IconButton from '@mui/material/IconButton';
import { connect } from 'react-redux';
import CircularProgress from '@mui/material/CircularProgress';
import InfiniteScroll from "react-infinite-scroll-component";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
// components
import WorkflowHistoryList from './WorkflowHistoryList';
import LifeSherpaLoading from '../../LifeSherpaLoading';
import RctCollapsibleCard from '../../RctCollapsibleCard/RctCollapsibleCard';

//Actions
import { getWorkflowHistory, removeWorkflowDetailData, handleSelectWorkflowStatusCategory } from 'Actions'
import {getFilteredHistoryList} from "../../../helpers/helpers";

class WorkflowHistoryListPanel extends Component {
	constructor(props) {
		super(props)
		this.state = {
			showLegend: false,
			selected: "open"
		}
	}

	componentDidMount() {
		const {selectedWorkflowStatus} = this.props;
		if(selectedWorkflowStatus) {
			this.props.handleSelectWorkflowStatusCategory(selectedWorkflowStatus);
		} else {
			this.props.handleSelectWorkflowStatusCategory("open");
		}
		// this.setState({selected: selectedWorkflowStatus || "open"})
	}

	handleTabButton(selectedTab) {
		this.props.handleSelectWorkflowStatusCategory(selectedTab);
		this.props.removeWorkflowDetailData()
		// this.setState({selected: selectedTab}, () => {
		// 	this.props.removeWorkflowDetailData()
		// })
	}

	// handleloadmore = () => {
	// 	const { selectedWorkflow, selectedActivity , fullScreen} = this.props;
	// 	let {pageCount} = this.props;
	// 	const userId = localStorage.getItem("uid");
	// 	this.props.getWorkflowHistory(userId, selectedWorkflow.id, pageCount);
	// }
	
	render() {
		const { selectedWorkflow, workflowHistoryLoading, cancelWorkflowLoading, skipWorkflowTasksLoading, selectedWorkflowItem, selectedStatusCategory } = this.props;
		const {selected} = this.state
		const workflowHistory = getFilteredHistoryList(this.props.workflowHistory, selectedStatusCategory)  // this.props.workflowHistory.length > 0 ? this.props.workflowHistory.filter(item => selectedStatusCategory == "open" ? (item.status == selectedStatusCategory) || (item.status == "error") : item.status == selectedStatusCategory) : this.props.workflowHistory

		return (
			<RctCollapsibleCard
				colClasses=""
				heading={
					<div className="d-flex align-items-center justify-content-between text-white">
						<div className="d-flex align-items-center">
							<IconButton title="Back To Home" className='mr-2' onClick={() => this.props.handleCloseWorkflowHistory()}><ArrowBackIcon /></IconButton>
						</div>
						<div className='d-flex px-0 workflow-history'>
							<div className="w-auto m-2 workflow-tab-bar" style={{ padding: 3 + 'px', borderRadius: 8 + "px" }} >
								<button data-testid="completed-tab-button" className={`btn btn-sm my-auto ${selectedStatusCategory === "completed" ? "selectedTab" : "unSelectedTab"}`} onClick={() => this.handleTabButton('completed')}>
									Completed
								</button>
								<button data-testid="open-tab-button" className={`btn btn-sm my-auto ${selectedStatusCategory === "open" ? "selectedTab" : "unSelectedTab"}`} onClick={() => this.handleTabButton("open")}>
									Open
								</button>
								<button data-testid="cancelled-tab-button" className={`btn btn-sm my-auto ${selectedStatusCategory === "cancelled" ? "selectedTab" : "unSelectedTab"}`} onClick={() => this.handleTabButton("cancelled")}>
									Cancelled
								</button>
							</div>
						</div>
						<IconButton title="Back To Home" onClick={() => this.props.handleLengendInfo()}><ErrorOutlineIcon /></IconButton>
					</div>
				}
				fullBlock
				customClasses="overflow-hidden theme-background activities-history-list-panel"
				headingCustomClasses="bg-lifesherpa "
			>
				<div className="px-3 py-2 list-card sub-header-list-card screen-content-background-color">
					<div className="align-items-center">
						<div className=" mt-1">
							{selectedWorkflow && 
								<div className='d-flex align-items-center justify-content-between'>
									<h3 className="mb-0 heading-text">{selectedWorkflow.name}</h3>
									<h3 className="mb-0 heading-text">{workflowHistory && workflowHistory.length > 0 ? workflowHistory.length : ""}</h3>
								</div>
							}
							{selectedWorkflow && <p className="mb-0 sub-text">{selectedWorkflow.description}</p>}
						</div>
					</div>
				</div>
				<div className="activity-history-list" id="scrollable1">
					{/*!workflowHistory && <LifeSherpaLoading loading={workflowHistoryLoading} />*/}
					{<InfiniteScroll
						dataLength={workflowHistory && workflowHistory.length}
						// next={() => this.handleloadmore()}
						// hasMore={this.props.hasMoreData}
						style={{ overflow: "none" }}
						scrollableTarget='scrollable1'
					>
						<LifeSherpaLoading loading={workflowHistoryLoading || cancelWorkflowLoading || skipWorkflowTasksLoading}/>
						<WorkflowHistoryList
							handleOpenWorkflowDetails={this.props.handleOpenWorkflowDetails}
							workflowHistory={workflowHistory}
							selectedTab={selected}
						/>
						{/*workflowHistory && workflowHistory.length > 0 && workflowHistoryLoading &&
							<div className="d-flex justify-content-center my-3">
								<CircularProgress color='progress' />
							</div>
						*/}
					</InfiniteScroll>}
				</div>

			</RctCollapsibleCard>
		);
	}
}

const mapStateToProps = ({ workflowData, settings }) => {
	const { selectedWorkflow, selectedWorkflowItem, showWorkflowHistory,  workflowHistory, workflowHistoryLoading, cancelWorkflowLoading, skipWorkflowTasksLoading, selectedStatusCategory} = workflowData
	const { popupThemeClass } = settings;
	return { popupThemeClass, selectedWorkflow, showWorkflowHistory,  workflowHistory, workflowHistoryLoading, cancelWorkflowLoading, skipWorkflowTasksLoading, selectedWorkflowItem, selectedStatusCategory };
};

export default connect(mapStateToProps, {
	getWorkflowHistory,
	removeWorkflowDetailData,
	handleSelectWorkflowStatusCategory
})(WorkflowHistoryListPanel);
