import React from "react";
import { ListItem } from "@mui/material";
import moment from 'moment'
import { openPopup } from "../../helpers/helpers";

const styles = {
  listItem: (isOwnMessage) => ({
   justifyContent: isOwnMessage ? "end" : "start",
  }),
  container: (isOwnMessage) => ({
    maxWidth: "75%",
    borderRadius: 12,
    padding: 16,
    fontSize: 12,
  }),
  author: { fontSize: 10, color: "gray" },
  timestamp: { fontSize: 8, textAlign: "right", paddingTop: 4 },
};

class ChatItem extends React.Component {
  handleOpenLink = (message) => {
    if(message && message.includes("Join Video Call: "))  {
      console.log(" open link url --> ", message.split(": "))
      let openVideoCallRoom = message.split(": ")[1];
      // window.open(openVideoCallRoom,"_blank");
      var windowReference = window.open();
      windowReference.location = openVideoCallRoom;
      openPopup(windowReference)
    }
  }
  render() {
    const { message, email } = this.props;
    const isOwnMessage = message.author === email;
    const htmlFormat = message.body.replaceAll("\n", "<br />");
    //console.log("htmlFormat --> ", htmlFormat);
    return (
      <ListItem style={styles.listItem(isOwnMessage)}>
         {/* <div style={styles.author}>{message.author}</div>  */}
          <div className={`d-flex align-items-end ${isOwnMessage ? "justify-content-end":"justify-content-start"}`}>
            {isOwnMessage && 
              <div className="chat-date-style mb-1">
              <div>{moment(message.dateCreated.toISOString()).format("MM/DD/YY")}</div>
              <div>{moment(message.dateCreated.toISOString()).format("hh:mm A")}</div>
            </div>}
            <div 
              className={`message-text w-75 ${isOwnMessage ? "localMessage":"userMessage"} ${message.body && message.body.includes("Join Video Call: ")? "cps" : ""}`} 
              onClick={() => this.handleOpenLink(message.body)}
              dangerouslySetInnerHTML={{ __html: htmlFormat}}
            />

            {!isOwnMessage && 
              <div className="chat-date-style mb-1">
              <div>{moment(message.dateCreated.toISOString()).format("MM/DD/YY")}</div>
              <div>{moment(message.dateCreated.toISOString()).format("hh:mm A")}</div>
            </div>}
          </div>
      </ListItem>
    );
  }
}

export default ChatItem;