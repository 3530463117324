/**
* Main App
*/
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
const MainApp = require('./App').default;
const AppWidget = require('./AppWidget').default;
const ExternalPages = require('./externalPages/App').default;

// app component
const AppNavigator = () => (
    <Router>
        <Switch>
            <Route path="/widget/v3/eastbay/:widgetApp" component={AppWidget} />
            <Route path="/widget/v3/:widgetApp" component={AppWidget} />
            <Route path="/widget/v2/:widgetApp" component={AppWidget} />
            <Route path="/widget/:widgetApp" component={AppWidget} />
            <Route path="/external-links" component={ExternalPages} />
            <Route path="/" component={MainApp} />
        </Switch>
    </Router>

);

export default AppNavigator;