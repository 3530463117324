import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import {
    
    getUsersNotActiveOnWorkflows,
    handleUserWorkflowPanel,
    getUserPendingWorkflowList
} from '../../../actions'
import { connect } from 'react-redux';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

class WorkflowNotices extends Component {
    state = {
        InActiveApplicantTitle: "Users Not Currently In A Workflow"
    }
   
    componentDidMount() {
        let uid = localStorage.getItem('auth_uid')

        const orgId = localStorage.getItem("orgId")
        const { widgetVersion, userRole } = this.props;
        if(orgId === "dzr5fQzvEBM00Tu2n5kY") {
           this.setState({InActiveApplicantTitle: "Applicants not having any active workflow"});
        }

        if (widgetVersion && (widgetVersion === 1 || widgetVersion === 2) && userRole && userRole !== 'Client') {
            this.props.getUsersNotActiveOnWorkflows(orgId)
        } else {
            if (userRole && userRole !== 'Client') {
                this.props.getUsersNotActiveOnWorkflows(orgId)
            }
            this.props.getUserPendingWorkflowList({ userId: uid, orgId })
        }

    }

    handleWorkflow = () => {
        const userId = localStorage.getItem("auth_uid")
        this.props.handleUserWorkflowPanel(true, userId)
    }


    render() {
        const { userRole, widgetVersion, userHasNoActiveWorkflow, userWorkflows, enableWorkflows } = this.props;

        if (enableWorkflows) {
            return (
                <div>
                    <div data-testid="dashbaord-workflow-notices-container" className="list-card mb-2 justify-content-between align-items-center px-1 py-1 pb-2 mt-2">
                        <div className='cursor-pointer'>
                            <div className="d-flex mx-2 my-1 align-items-center justify-content-between" >
                                <h3 className="heading-text" >Workflow Notices</h3>
                            </div>
                            {!widgetVersion &&
                                <>
                                    <hr className="my-2" />
                                    <div data-testid="dashbaord-task-awaiting-container" className="d-flex align-items-center justify-content-between cps" onClick={() => this.handleWorkflow()}>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <span className="badge-orange px-2 mx-2 my-auto" data-testid="workflow-awaiting-task-count">{(userWorkflows && userWorkflows.count) || 0}</span>
                                            <div className="heading-text">Tasks Awaiting Completion in Workflows</div>
                                        </div>
                                        <ArrowRightIcon />
                                    </div>
                                </>
                            }
                            {userRole && userRole !== 'Client' && userHasNoActiveWorkflow ?
                                <>
                                    <hr className="mt-1 mb-2" />
                                    <div data-testid="dashbaord-applicant-without-workflow-container" className="d-flex align-items-center">
                                        <span className="badge-primary px-2 mx-2 my-auto" data-testid="applicant-have-no-workflow-on-dashbaord" >{(userHasNoActiveWorkflow && userHasNoActiveWorkflow.count) || 0 }</span>
                                        <div className="heading-text cps">{this.state.InActiveApplicantTitle}</div>
                                    </div>
                                </>
                                : ""
                            }
                        </div>
                    </div>
                </div>
            );
        } else {
            return (<></>);
        }
    }
}
const mapStateToProps = ({  authUser, workflowData }) => {
    const { userRole } = authUser;
    const { userHasNoActiveWorkflow, userWorkflows } = workflowData
    return { userHasNoActiveWorkflow, userWorkflows, userRole  };
}

export default withRouter(connect(mapStateToProps, {
    handleUserWorkflowPanel,
    getUsersNotActiveOnWorkflows,
    getUserPendingWorkflowList
})(WorkflowNotices));