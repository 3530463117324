/**
 * Ecommerce Dashboard
 */

import React, { Component } from 'react'
import { connect } from 'react-redux'
import queryString from 'query-string';
import Moment from 'moment';
import SweetAlert from 'react-bootstrap-sweetalert'

// rct collapsible card
import RctCollapsibleCard from '../../../components/RctCollapsibleCard/RctCollapsibleCard'
import LandingPageAllActivity from '../../../components/SherpaActivities/LandingPageAllActivity';
import ActivityDetails from "../../../components/UserDetails/components/ActivityDetails";
import DashboardSummary from "../../../components/AppCenter";
import Notices from '../../../components/Notices';
import ClientDashboard from '../../../components/UserDetails/components/ClientDashboard';

import MoreVertIcon from '@mui/icons-material/MoreVert'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import NotificationsIcon from '@mui/icons-material/Notifications';
import ListIcon from '@mui/icons-material/List';
import IconButton from '@mui/material/IconButton';
import WorkflowExecutionHistory from '../../../components/WorkflowExecutionHistory';
//Actions
import {
	selectActivityId,
	selectActivity,
	handlEditor,
	getResourceList,
	getcategories,
	getKeywords,
	changeRole,
	showClientPanel,
	getlaunchpad,
	getMyCourses,
	getCourseLaunchURL,
	getActivitiesList,
	getUserById,
	selectMenuItem,
	showAssignActivityIFramePanel,
	getActivityGroupsList,
	executeActivityWithAchievementId,
	hidePushNotiAlert,
	networkActivity,
	hideActivityHistoryDetail,
	handleHideActivityDetailsPanel,
	getactivitiesCalendar,
	clearNoticesData,
	handleShowClientDashboard,
	selectMyCourseItem,
	closeCorseIframe,
	clearPokeFormData,
	handleOpenLauncPadUrl,
	clearCourseData,
	clearUserOrgFilter,
	showExecuteActivityPannel,
	handleUserWorkflowPanel
} from '../../../actions'

import {
	ActivitySupportCourses,
	BolletinBoard,
	MyClientsPanel
} from "../../../components/Widgets";
import LSWorkflowList from '../../lsworkflow/components/LSWorkflowList';

import UserDetails from '../../../components/UserDetails';
import CoursesComponent from '../../../components/Courses';
import AssignActivityComponent from "../../../components/AssignActivity";
import { withAuth0 } from "@auth0/auth0-react";
import RewardsPage from "../../../components/SherpaActivities/RewardsPage"

import AchievementHistoryList from "../../../components/AchievementHistoryList"
import ActivityEditorSidebar from "../../../components/AppMyActivity/components/ActivityEditorSidebar"

import UserWorkflow from "../../../components/Workflows/components/UserWorkflow"

class LifesherpaDashboard extends Component {

	constructor(props) {
		super(props)
		this.state = {
			role: null,
			openChart: false,
			firebaseToken: null,
			selected: '',
			activityId: '',
			activityType: '',
			previewId: null,
			showDetails: '',
			coursePanel: false,
			openEditor:false,
			editorType:null,
			openMenu: false,
			anchorEl: null
		}
	}
	showUserDetails = (isShowClientDetail) => {
		if (isShowClientDetail) {
			console.group("%c[UserDetails]%c Rendered user details panel", "color:black", "color:green");
		} else {
			console.log("%c[UserDetails]%c Closed user details panel", "color:black", "color:red");
			console.groupEnd();
		}
		let auth_uid = localStorage.getItem('auth_uid');
		const orgId = localStorage.getItem("orgId")
		const orgIds = localStorage.getItem("orgIds") ? localStorage.getItem("orgIds").split(",") : null
		if (this.props.clientPanel) {
			localStorage.setItem('uid', auth_uid);
			localStorage.setItem("selectedOrgId", orgId)
			this.props.changeRole({ role: 'admin', id: auth_uid });
			this.props.getResourceList('', '', '');
			this.props.getcategories();
			this.props.getKeywords();
			this.props.getMyCourses();
			this.props.getActivityGroupsList(auth_uid, orgIds);
			this.props.getActivitiesList(auth_uid, '', this.props.selectedGroup);
			this.props.getactivitiesCalendar(auth_uid);
			this.props.handleHideActivityDetailsPanel();
		}
		this.props.showClientPanel(isShowClientDetail);
	}

	componentDidMount() {
		this.props.clearNoticesData();
		this.props.clearPokeFormData();
		this.props.clearCourseData();
		this.props.handleShowClientDashboard(false, null)
		this.handleOpenNotificationStartActivity();
		this.props.hideActivityHistoryDetail()
		// localStorage.setItem('selectedIndex', 0)
		const {widgetApp} = this.props;
		this.props.selectMenuItem(0)
		const auth_uid = localStorage.getItem('auth_uid');
		const uid = localStorage.getItem('uid');
		const idToken = localStorage.getItem('idToken')
		const selectedGroup = localStorage.getItem('selectedGroup');
		const course = localStorage.getItem("course")
		const orgId = localStorage.getItem("orgId");
		const selectedOrgId = localStorage.getItem("selectedOrgId");
		if(orgId && !selectedOrgId) {
			localStorage.setItem("selectedOrgId", orgId);
		}
		const orgIds = localStorage.getItem("orgIds") ? localStorage.getItem("orgIds").split(",") : null
		// this.props.networkActivity();
		if (uid != auth_uid) {
			localStorage.setItem('uid', auth_uid);
			localStorage.setItem("selectedOrgId", orgId)
			this.props.showClientPanel(false);
			this.props.changeRole({ role: 'admin', id: auth_uid });
			this.props.handleHideActivityDetailsPanel();
		}
		if (this.props.assingnActivityPanel) {
			this.props.showAssignActivityIFramePanel(false)
		}
		if (idToken && auth_uid) {
			// if(course) {
			// 	const selectedCourse = JSON.parse(course)
			// 	this.props.selectMyCourseItem(selectedCourse)
			// 	this.props.getCourseLaunchURL(selectedCourse.id, null, false)
			// }
			this.props.clearUserOrgFilter({panel:'activities'});
			this.props.clearUserOrgFilter({panel:'courses'});
			this.props.clearUserOrgFilter({panel:'resources'});
			this.props.getMyCourses()
			this.props.selectActivity(false);
			this.props.getUserById(auth_uid, idToken, widgetApp)
			this.props.getActivitiesList(auth_uid, '', this.props.selectedGroup);
			this.props.getactivitiesCalendar(auth_uid);
			this.props.getActivityGroupsList(auth_uid, orgIds);
		}

		if (this.props.location && this.props.location.search) {
			const params = queryString.parse(this.props.location.search)
			this.setState({ role: params.role })
		}
		this.setState({ firebaseToken: idToken });
	}
	handleOpenNotificationStartActivity = () => {
		const params = this.getParams();
		if (params && params.achievementId) {
			console.log(" params.achievementId --> ", params.achievementId);
			let date = Moment().format('YYYY-MM-DD HH:mm:ss Z');
			let uid = localStorage.getItem('auth_uid');
			this.props.executeActivityWithAchievementId(params.achievementId, date, uid);
			this.props.selectActivityId(params.achievementId);
			this.props.handlEditor(true);
			this.props.history.push("/app/lsdashboard");
		}
	}

	getParams() {
		let params = null
		if (this.props.location && this.props.location.search) {
			params = queryString.parse(this.props.location.search)
		} else if (window.location && window.location.search) {
			params = queryString.parse(window.location.search)
		}
		return params
	}
	handleStartActivity = (e) => {
		this.props.handlEditor(true)
		this.setState({ previewId: null, mobileOpen: true, activityId: e.activityId, activityType: e.type }, () => {
			this.props.showExecuteActivityPannel('start')
		})
	}

	handleRewards = () => {
		this.props.selectActivity(null)
		this.props.handlEditor(false)
		let uid = localStorage.getItem('uid')
		let idToken = localStorage.getItem('idToken')
		this.props.getUserById(uid, idToken)
		this.setState({ previewId: null, mobileOpen: false, activityId: '', activityType: '', showRewards: true }, () => {
			this.props.showExecuteActivityPannel('')
		})
	}

	handleClose3 = () => {
		this.props.selectActivityId(null)
		this.props.selectActivity(null)
		this.props.handlEditor(false)
		let uid = localStorage.getItem('uid')
		this.setState({ previewId: null, mobileOpen: false, activityId: '', activityType: '' }, () => {
			this.props.showExecuteActivityPannel('')
		})
	}

	handleClose2 = () => {
		this.props.selectActivityId(null)
		this.props.selectActivity(null)
		this.props.handlEditor(false)
		this.setState({ previewId: null, mobileOpen: false, activityId: '', activityType: '', openForm: true }, () => {
			this.props.showExecuteActivityPannel('start')
		})
	}

	handleDrawerToggle = () => {
		this.setState({ mobileOpen: !this.state.mobileOpen });
	};

	getCourseLaunchURL = (course) => {
		// const courseId = course.id
		// this.props.getCourseLaunchURL(courseId);
		// let key = 3;
		// localStorage.setItem('selectedIndex', JSON.stringify(key))
		// this.props.history.push(`/app/lms_course`);
		console.group("%c[LMSCourse]%c Rendered LMS iFrame", "color:black", "color:green");
		localStorage.setItem("course",JSON.stringify(course))
		const courseId = course.id
		this.props.selectMyCourseItem(course)
		this.props.getCourseLaunchURL(courseId, null, false);
		this.setState({ coursePanel: true, selectedCourse: course.name })
	}

	previewId = (e) => {
		this.props.selectActivity(true)
		this.props.selectActivityId(e);
		localStorage.setItem('selectedIndex', JSON.stringify(1))
		localStorage.setItem('previewId', e);
		this.props.history.push(`/app/user/my_activities`);
	}

	handleCloseCourse() {
		const {openLaunchPad} = this.props;
		if(openLaunchPad) {
			this.props.handleOpenLauncPadUrl({data:null, show: false});
			return;
		}
		console.log("%c[LMSCourse]%c Closed LMS iFrame", "color:black", "color:red");
		console.groupEnd();
		localStorage.removeItem("course")
		this.props.getMyCourses()
		this.setState({ coursePanel: false, selectedCourse: '' })
		this.props.closeCorseIframe()
	}
	handleCloseAssignActivity() {
		this.setState({ assingActivityInfo: {} });
		this.props.showAssignActivityIFramePanel(false)
	}
	handleOpenAssingActivity = (assingActivityInfo) => {
		// console.log("assingnActivity data --->", assingActivityInfo)
		console.group("%c[AssignActivityiFrame]%c Rendered Assign Activity iFrame", "color:black", "color:green");
		this.setState({assingActivityInfo: assingActivityInfo });
		this.props.showAssignActivityIFramePanel(true);
	}

	handleOpenObjectEditor = (type) => {
		console.log("add icon clicked -->", type)
		this.setState({openEditor: true, activityId: null, editorType:type})
	}

	handleCloseObjectEditor = () => {
		this.setState({openEditor: false, activityId: null, editorType:null})
		const auth_uid = localStorage.getItem('auth_uid');
		this.props.getActivitiesList(auth_uid, '', this.props.selectedGroup);
		this.props.getactivitiesCalendar(auth_uid);
	}

	handleEditActivity = (activityId, editorType) => {
		console.log("edit activity -->", activityId, editorType)
		this.setState({openEditor: true, activityId: activityId, editorType})
	}

	handleRoutineType(type){
		this.setState({openEditor: true, activityId: null, editorType: type, openMenu: !this.state.openMenu})
    }

    handleActivityMenu(event){
        console.log("openActivity menu--->", event.currentTarget)
        this.setState({anchorEl: event.currentTarget, openMenu: !this.state.openMenu})
    }
	
	getScrollHeight() {
		if (localStorage.getItem("widgetApp")) {
			return 'calc(100vh)';
		} else {
			return '630px';
		}
	}

	render() {
		window["auth0"] = this.props.auth0
		let { selected, activityId, activityType, userDetails, coursePanel, assingActivityInfo, editorType, openMenu, anchorEl, openEditor } = this.state;
		const { clientPanel, match, userRole, launchpad, loading, myCourseLoading, myCourses, allowedModules, showLMSWidget, assingnActivityPanel, openChatPanel, displayPushNotiAlert, displayOnce, showActivityHistory, popupThemeClass, encounterActivities, showActivityDetailsPanel, showEncounterActivityDetails, hideRewards, selectedCourse, openCourseIframe, openLaunchPad, selectedLanuchPad, coursePanelOrgFiltered, groupMembersDetails, filteredActivities, showWorkflowHistory, userWorkflowPanel, workflowslist, enableWorkflows } = this.props;
		//console.log(myCourses && myCourses.courses ? myCourses.courses : null);
		let list = this.props.selectedRole === 'admin' ? this.props.privateActivities : this.props.allActivities;
		// console.log("allowedModules",allowedModules);
		const clientsCounts = groupMembersDetails && groupMembersDetails.length > 0 ? groupMembersDetails.length : 0
		const activityCounts = filteredActivities && filteredActivities.length > 0 ? filteredActivities.length : list && list.length > 0 ? list.length : 0
		
		return (
			<div className="w-100 " data-testid="ls-dashboard">
				{
					showWorkflowHistory ?
						<WorkflowExecutionHistory 
							userId={localStorage.getItem("uid")}
							orgId={localStorage.getItem("selectedOrgId")}
						/>
					:showActivityHistory ?
						<AchievementHistoryList popupThemeClass={this.props.popupThemeClass} />
						: assingnActivityPanel ?
							<AssignActivityComponent
								handleCloseAssignActivity={this.handleCloseAssignActivity.bind(this)}
								assingActivityInfo={assingActivityInfo}
							/> 
						: clientPanel ?
							<UserDetails
								previewId={this.previewId}
								handleStartActivity={this.handleStartActivity}
								showUserDetails={this.showUserDetails}
								handleOpenAssingActivity={this.handleOpenAssingActivity}
							/> 
						: showActivityDetailsPanel && showEncounterActivityDetails ?
							<ActivityDetails handleStartActivity={this.props.handleStartActivity} />
						: openCourseIframe || openLaunchPad /*coursePanel*/ ?
							<CoursesComponent
								handleCloseCourse={this.handleCloseCourse.bind(this)}
								title={(selectedLanuchPad &&selectedLanuchPad.label) || (selectedCourse && selectedCourse.name)}
								tag={openLaunchPad ? "Launchpad": "Courses"}
							/>
						:
						<div className="row">

							<RctCollapsibleCard
								colClasses="col-sm-12 col-md-12 col-lg-6 col-xl-4 w-xs-full"
								heading={<div className="d-flex align-items-center justify-content-between text-white">
									<div>Dashboard Summary</div>
								</div>
								}
								fullBlock
								customClasses="overflow-hidden theme-background "
								headingCustomClasses="bg-lifesherpa "
							>
								<DashboardSummary
									handleStartActivity={this.handleStartActivity}
									showUserDetails={this.showUserDetails}
									userRole={userRole}
								/>
							</RctCollapsibleCard>
							
							{userRole && userRole !== 'Client' ?
								<RctCollapsibleCard
									colClasses="col-sm-12 col-md-12 col-lg-6 col-xl-4 w-xs-full"
									heading={<div className="d-flex align-items-center justify-content-between text-white">
										<div>{"My Clients" /*`My Clients ${clientsCounts > 0 ? "(" + clientsCounts + ")" : ""}`*/}</div>
										{/* <div onClick={() => this.openActivity("clients")}>
											{this.props.groupMembers && Object.keys(this.props.groupMembers).length > 0 ? "(" + Object.keys(this.props.groupMembers).length + ")" : ""}
										</div> */}
									</div>
									}
									fullBlock
									customClasses="overflow-hidden theme-background"
									headingCustomClasses="bg-lifesherpa "
								>
									<MyClientsPanel showUserDetails={this.showUserDetails} handleOpenAssingActivity={this.handleOpenAssingActivity} />
								</RctCollapsibleCard> 
								: <></>
							}
							{enableWorkflows ?
								<RctCollapsibleCard
									colClasses="col-sm-12 col-md-12 col-lg-6 col-xl-4 w-xs-full"
									heading={workflowslist && workflowslist.length > 0 ? `My Workflows(${workflowslist.length})` : "My Workflows"}
									fullBlock
									customClasses="overflow-hidden theme-background "
									headingCustomClasses="bg-lifesherpa "
									>
									<LSWorkflowList 
										showViewStatus={true}
										userId={localStorage.getItem("uid")}
										orgId={localStorage.getItem("selectedOrgId")}
										/>
								</RctCollapsibleCard>
								: <></>
							}
							<RctCollapsibleCard
								colClasses="col-sm-12 col-md-12 col-lg-6 col-xl-4 w-xs-full"
								heading={<div className="d-flex align-items-center justify-content-between text-white">
									<div>{`My Activities ${activityCounts > 0 ? "(" + activityCounts + ")" : ""}`}</div>
									<div   /*onClick={() => this.openActivity("clients")}*/>
										{/* {list && list.length > 0 ? "(" + list.length + ")" : ""} */}
										<IconButton title="Add Routine" className="p-0" data-testid={`client-activity-mor-icon`} onClick={(event) => this.handleActivityMenu(event)}>
                                            <MoreVertIcon color="primary" aria-label="More" aria-owns={anchorEl ? 'create-activity-menu' : null} aria-haspopup="true" />
                                        </IconButton>
									</div>
								</div>
								}
								fullBlock
								customClasses="overflow-hidden theme-background "
								headingCustomClasses="bg-lifesherpa "
							>
								<LandingPageAllActivity
									previewId={this.previewId}
									handleStartActivity={this.handleStartActivity} 
									handleEditActivity={this.handleEditActivity}
								/>
									

							</RctCollapsibleCard>
							{(showLMSWidget && allowedModules && allowedModules.LMSInstructor) ?
								<RctCollapsibleCard
									colClasses="col-sm-12 col-md-12 col-lg-6 col-xl-4 w-xs-full"
									heading={<div className="d-flex align-items-center justify-content-between text-white">
										<div>{`My Courses ${myCourses && myCourses.courses && myCourses.courses.length > 0 ? "(" + myCourses.courses.length + ")" : ""}`}</div>
										{/* <div >
											{myCourses && myCourses.courses && myCourses.courses.length > 0 ? "(" + myCourses.courses.length + ")" : ""}
										</div> */}
									</div>
									}
									fullBlock
									customClasses="overflow-hidden theme-background "
									headingCustomClasses="bg-lifesherpa"
								>
									<ActivitySupportCourses filterApplied={coursePanelOrgFiltered && coursePanelOrgFiltered.length > 0 ? true : false} getCourseLaunchURL={this.getCourseLaunchURL} myCourseLoading={myCourseLoading} myCourses={myCourses && myCourses.courses ? myCourses.courses : null} launchpad={launchpad} />
								</RctCollapsibleCard>
								: <></>
							}
							<RctCollapsibleCard
								colClasses="col-sm-12 col-md-12 col-lg-6 col-xl-4 w-xs-full"
								heading={<div className="d-flex align-items-center justify-content-between text-white">
									<div>{`My Resources ${this.props.categories ? "(" + this.props.categories.reduce((acc, curr) => acc + curr.totalCount, 0) + ")" : ""}`}</div>
									{/* <div >
										{this.props.categories ? "(" + this.props.categories.reduce((acc, curr) => acc + curr.totalCount, 0) + ")" : ""}
									</div> */}
								</div>
								}
								fullBlock
								customClasses="overflow-hidden theme-background "
								headingCustomClasses="bg-lifesherpa"
							>	
								<BolletinBoard />
							</RctCollapsibleCard>
							{hideRewards === null || hideRewards === undefined || !hideRewards ?
								<RctCollapsibleCard
									colClasses="col-sm-12 col-md-12 col-lg-6 col-xl-4 w-xs-full"
									//heading="My Rewards"
									fullBlock
									customClasses="overflow-hidden theme-background "
									headingCustomClasses="bg-lifesherpa"
									>
									<RewardsPage /> 
								</RctCollapsibleCard>
								:
								<></>
							}
							{this.state.openEditor ? 
								<ActivityEditorSidebar
									handleCloseObjectEditor={this.handleCloseObjectEditor.bind(this)}
									openEditor={openEditor}
									activityId={activityId}
									editorType={editorType}
								/> : <></>
							}
							<Menu id="create-activity-menu" anchorEl={anchorEl} open={openMenu} onClose={(event) => this.handleActivityMenu(event)}>
								<MenuItem title="Activity Type" className="menuEditItem" onClick={() => this.handleRoutineType("general")}><ListIcon className='mr-2'/>Create New Activity</MenuItem>
								<MenuItem title="Notification Type" className="menuEditItem" onClick={() => this.handleRoutineType("notification")}><NotificationsIcon className='mr-2' />Create New Notification</MenuItem>
							</Menu>
						</div>
				}
				{this.props.noticePanel && <Notices/>}
				{userWorkflowPanel && 
					<UserWorkflow 
						openUserWorkflow={userWorkflowPanel} 
						/>
					}
				{this.props.showClientDashBaord && <ClientDashboard/>}

				{displayPushNotiAlert && !displayOnce ?
					<SweetAlert
						warning
						btnSize="sm"
						customClass="warningText"
						confirmBtnText="OK"
						confirmBtnBsStyle="warning"
						title="Push Notification Alert!"
						onConfirm={() => this.props.hidePushNotiAlert(false)}
					>
						<div className="sweet-alert-text">Currently, push notifications are not supported on Safari. If notification is required then you can use other browsers like Google Chrome, Firefox, Edge etc.</div>
					</SweetAlert>
					:
					<></>
				}
			</div>
		)
	}
}

const mapStateToProps = ({ authUser, activitiesList, Charts, MyCourses, clientsList, GroupList, Resources, chatAppReducer, settings, emailApp, noticesReducer, workflowData }) => {
	let { clientPanel, showClientDashBaord, coursePanelOrgFiltered } = clientsList;
	const { userRole, allowedModules, displayPushNotiAlert, displayOnce, hideRewards, enableWorkflows } = authUser
	const { allActivities, privateActivities, launchpad, encounterActivities, showActivityDetailsPanel, showEncounterActivityDetails, filteredActivities } = activitiesList
	const { usersCharts, loading } = Charts
	const { selectedUser, displayName, profileImage, selectedMemberId, selectedRole, openChatPanel } = chatAppReducer
	let { groupMembers, groupMembersDetails } = GroupList;
	const { resourceList, categories } = Resources;
	const { myCourseLoading, myCourses, showLMSWidget, selectedCourse, openCourseIframe, openLaunchPad, selectedLanuchPad } = MyCourses;
	const { screenThemeClass, popupThemeClass, assingnActivityPanel } = settings;
	const { showActivityHistory } = emailApp;
	const {noticePanel} = noticesReducer
	const {showWorkflowHistory, userWorkflowPanel, workflowslist} = workflowData
	return {openLaunchPad, selectedLanuchPad, showClientDashBaord, selectedRole, categories, groupMembers, resourceList, allActivities, privateActivities, userRole, launchpad, loading, myCourseLoading, myCourses, clientPanel, allowedModules, showLMSWidget, screenThemeClass, popupThemeClass, assingnActivityPanel, displayPushNotiAlert, displayOnce, showActivityHistory, encounterActivities, showActivityDetailsPanel, showEncounterActivityDetails, hideRewards, noticePanel, selectedCourse, openCourseIframe, coursePanelOrgFiltered, groupMembersDetails, filteredActivities, showWorkflowHistory, userWorkflowPanel, workflowslist, enableWorkflows }
}

export default withAuth0(connect(mapStateToProps, {
	getlaunchpad,
	selectActivityId,
	selectActivity,
	handlEditor,
	getMyCourses,
	getCourseLaunchURL,
	getResourceList,
	getcategories,
	getKeywords,
	changeRole,
	showClientPanel,
	getActivitiesList,
	getUserById,
	selectMenuItem,
	showAssignActivityIFramePanel,
	getActivityGroupsList,
	executeActivityWithAchievementId,
	hidePushNotiAlert,
	networkActivity,
	hideActivityHistoryDetail,
	handleHideActivityDetailsPanel,
	getactivitiesCalendar,
	clearNoticesData,
	handleShowClientDashboard,
	selectMyCourseItem,
	closeCorseIframe,
	clearPokeFormData,
	handleOpenLauncPadUrl,
	clearCourseData,
	clearUserOrgFilter,
	showExecuteActivityPannel,
	handleUserWorkflowPanel
})(LifesherpaDashboard))
