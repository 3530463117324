import React, { Component } from 'react';

// Material UI Components
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from "@mui/material/DialogTitle";
import CloseIcon from '@mui/icons-material/Close';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import IconButton from '@mui/material/IconButton';
import { Label, FormFeedback } from 'reactstrap';
import LifeSherpaLoading from '../../LifeSherpaLoading';
// import Slide from '@mui/material/Slide';
import TextField from '@mui/material/TextField';
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from '@mui/icons-material/Search';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import Button from '@mui/material/Button';


class UserPendingTasksList extends Component {

    state = {
        showSearch: false,
        search: ""
    }

    componentDidMount() {
    }

    handleClosePanel() {
        this.props.handleCloseUseWorflow()
        const userId = localStorage.getItem("uid")
        const auth_uid = localStorage.getItem("auth_uid")
        let orgId = localStorage.getItem("selectedOrgId")
        if (auth_uid === userId) {
            orgId =  localStorage.getItem("orgId")
            localStorage.setItem("selectedOrgId", orgId)
        } 
        this.props.getUserPendingWorkflowList({userId, orgId})
    }

    handleNotifyProceed(pendingTask, userWorkflow) {
        // this.props.handleUserWorkflowSelect(userWorkflow)
        const {showProceedButton} = this.props
        if (showProceedButton) {
            this.props.startExecutingTask(pendingTask, userWorkflow)
        } else {
            this.props.notifyClientForTask(pendingTask, userWorkflow)
        }
    }

    handelSearch = (e) => {
        this.setState({ search: e.target.value });
    }

    handelCloseSearch = () => {
        this.setState({ search: "", showSearch: false});
    }
    matchText(text, search) {
        console.log("text, search :", text, search, text && text.toLowerCase().includes(search.toLowerCase()))
        return text && text.toLowerCase().includes(search.toLowerCase());
    }

    render() {
        const {clientName, userWorkflowLoading, organizations, showProceedButton, userRole, userWorkflows} = this.props
        const {search, showSearch} = this.state;
        

        // const userWorkflows = search != "" && this.props.userWorkflows && this.props.userWorkflows.length > 0 && search.length > 0 ? this.props.userWorkflows.filter(workflow => workflow.executionName.toLowerCase().includes(search.toLowerCase())) : this.props.userWorkflows
        const workflowsSupportOrgIds = organizations ? organizations.filter(org => org.enableWorkflows): [];
        const isAdminUser = localStorage.getItem("auth_uid") == localStorage.getItem("uid")
        let pendingTasksList = [];
        if(userWorkflows && userWorkflows.length) {
            for(const workflow of userWorkflows) {
                if(workflow.tasks) {
                    const clonedWorkflow = {...workflow};
                    delete clonedWorkflow.tasks;
                    for(const task of workflow.tasks) {
                        const pTask = {...task, workflow: clonedWorkflow};
                        if(!pTask.description) {
                            pTask.detailedDescription = `This task is part of workflow: "${workflow.executionName}"`
                        }
                        if(search && search.length > 1) {
                            if(this.matchText(pTask.name, search) || this.matchText(pTask.description, search) || this.matchText(pTask.detailedDescription, search)) {
                                pendingTasksList.push(pTask)
                            }  
                        } else {
                            pendingTasksList.push(pTask)
                        }
                    }
                }

            }
        }
        pendingTasksList.sort((task1, task2) => {
            return task1.name.localeCompare(task2.name);
        });
        
        return (
            <React.Fragment>
                <DialogTitle>
                    <div className={`d-flex all-clients-for-grooup justify-content-between align-items-center border-bottom bg-lifesherpa pl-2 py-2`}>
                        {!showSearch ? <Label data-testid="scheduleHeading" className="listItemTitle mb-0 text-white">{`${clientName == "My" ? clientName : clientName + "'s"} Workflow with Pending Tasks`}</Label> : <></>}
                            {showSearch ?
                                <form className="mr-3 w-100">
                                    <TextField id="standard-search"
                                        onChange={(e) => this.handelSearch(e)}
                                        type="search"
                                        value={search}
                                        placeholder="Search workflow"
                                        autoFocus={true}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <SearchIcon />
                                                </InputAdornment>
                                            ),
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <CloseIcon color="primary" onClick={() => this.handelCloseSearch()} style={{ cursor: "pointer" }} />
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant="standard"
                                    />
                                </form>
                                :
                                <IconButton className=" ml-auto mr-1 " data-testid="search-button-on-pending-tasks-in-workflow" onClick={() => this.setState({ showSearch: true })}><SearchIcon /></IconButton>
                            }
                        <IconButton data-testid="close-pending-tasks-in-workflow" className="text-white mr-2" onClick={() => this.handleClosePanel()}><CloseIcon /></IconButton>
                    </div>
                </DialogTitle>
                <DialogContent className="m-0 p-0 start-workflow-container ">
                    <LifeSherpaLoading loading={userWorkflowLoading} />
                    {/* <Slide direction={"right"} timeout={500} appear={this.props.pageMounted} in={true}> */}
                        <ul className='pt-3 pl-2 pb-3 pr-2 list-unstyled'>
                        { pendingTasksList && pendingTasksList.length > 0 ?  
                                pendingTasksList.map((pendingTask, key) => (
                                    <li key={key} className='p-3 list-card d-flex align-items-center justify-content-between'>
                                        <div className=''>
                                            <Label className='mb-0'>
                                                {workflowsSupportOrgIds.length > 1 ? 
                                                    <span title="Organization" className='mb-1 d-flex align-items-center justfy-content-center mr-1'>
                                                        <CorporateFareIcon className='mr-1'/>{organizations.find(org => org.id === (pendingTask.workflow.organization)).name}
                                                    </span>
                                                    : <></>
                                                }
                                                {pendingTask.name}
                                            </Label>
                                            {pendingTask.description ? 
                                                <p className='d-block m-0 task-warning-block quate-block-feedback'>{pendingTask.description}</p>
                                                : 
                                                <p className='d-block m-0 sub-text quate-block'>{pendingTask.detailedDescription}</p>
                                            }
                                        </div>
                                        {showProceedButton ?
                                            <Button color="primary" onClick={() => this.handleNotifyProceed(pendingTask, pendingTask.workflow)} variant="contained" className=" ml-2 primary-button pending-task-action-button">START</Button>
                                        : userRole && (userRole != 'Client') ? 
                                            <Button color="primary" onClick={() => this.handleNotifyProceed(pendingTask, pendingTask.workflow)} variant="contained" className=" ml-2 primary-button pending-task-action-button">NOTIFY</Button>
                                            : <></>
                                        }
                                    </li>
                                ))
                                  
                        : userWorkflowLoading ?
                            <></>
                        :
                            <li className='p-3 list-card d-flex align-items-center justify-content-between'>No workflow tasks are pending</li>
                        }
                        </ul>
                    {/* </Slide> */}
                </DialogContent>
            </React.Fragment>
        );
    }
}

export default UserPendingTasksList