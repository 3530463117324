/**
 * Landing Page Menu
 */
import React, { Component } from 'react'
import { connect } from 'react-redux'

//components
import IntlMessages from '../../util/IntlMessages'
import navLinks from './NavLinks'
import { NavLink } from 'react-router-dom'
import Badge from '@mui/material/Badge'
//materila icons
import HomeRoundedIcon from '@mui/icons-material/HomeRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import TuneIcon from '@mui/icons-material/Tune';
import ChatIcon from '@mui/icons-material/Chat';
import BarChartIcon from '@mui/icons-material/BarChart';
import CollectionsBookmarkIcon from '@mui/icons-material/CollectionsBookmark';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import ShowChartIcon from '@mui/icons-material/ShowChart';
import DashboardIcon from '@mui/icons-material/Dashboard';
import Diversity3RoundedIcon from '@mui/icons-material/Diversity3Rounded';
import { isAuth0SessionExpired, openPopup } from '../../helpers/helpers'
import {
  changeRole,
  selectMenuItem,
  getResourceList,
  getcategories,getKeywords,
  showClientPanel,
  getMyCourses,
  showAssignActivityIFramePanel,
  analyticsOpenedToNewTab,
  getHelpContactsWithChannel,
  clearHistoryData,
  getSelectedActivity,
  setSelectedMemberId,
  showActivityHistoryDetail,
  handleHideActivityDetailsPanel, 
  handleShowEncounterActivityDetails,
  handleShowEncounterActivityHistory,
  setClientActivitiesType,
  hideNotificationRelatedData,
  hideStartWorkflowPanel,
  clearCourseData
} from '../../actions';

class HorizontalMenu extends Component {
  constructor(props){
    super(props)
    this.state = {
      selectedIndex: 0
    }
    this.handleMenuItem = this.handleMenuItem.bind(this)
  }

  componentDidMount() {
    const {selectedMenuItem} = this.props;
    if(selectedMenuItem == 0){
      console.group("%c[LifeSherpPortal]%c Rendered Lifesherpa Portal Screen","color:black","color:green");
    }
		this.updateDimensions();
		window.addEventListener("resize", this.updateDimensions);
    this.props.hideNotificationRelatedData();
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.updateDimensions);
	}

	updateDimensions = () => {
		this.setState({ windowWidth: window.innerWidth });
  }
  printTabConsoleLog = (path) => {
    switch(path) {
      case "/app/usercharts":
        console.log("%c[UserModuleCharts]%c Rendered user reports page","color:black","color:green");
        break;
      case "/app/configurator":
        console.log("%c[Configurator]%c Rendered Configurator iFrame","color:black","color:green");
        break;
      case "/app/lms":
        console.log("%c[LMS]%c Rendered LMS iFrame","color:black","color:green");
        break;
      case "/app/zoho_analytics":
        console.log("%c[Zoho_Analytics]%c Opened Zoho Analytic in new Tab","color:black","color:green");
        break;
      default: 
        console.log("%c[LifeSherpDashboard]%c Rendered Lifesherpa DashBoard Home Screen","color:black","color:green");
          
    }
  }

  handleMenuItem(e, key, menu){
    console.groupEnd();
    isAuth0SessionExpired();
    if(key == 0) {
      this.props.clearCourseData();
    }
    
    const {selectedMenuItem, showActivityHistory, clientPanel} = this.props;
    if(!(key == 0 && selectedMenuItem == 0)) {
      console.log(" Selected index",key,"tab.....");
      this.printTabConsoleLog(menu.path)
    }

    if(menu.path != '/app/zoho_analytics') {
      this.props.selectMenuItem(key)
    }

    if(menu.path === "/app/lsdashboard" || menu.path === "/app/contacts") {
      this.props.getHelpContactsWithChannel();
    }

    this.props.hideStartWorkflowPanel()

    window.postMessage("messages for post message");
    let auth_uid=localStorage.getItem('auth_uid');
    let uid=localStorage.getItem('uid');
    const orgId = localStorage.getItem("orgId")
    //const orgIds = localStorage.getItem("orgIds") ? localStorage.getItem("orgIds").split(",") : [orgId]
    if (clientPanel) {
      this.props.showClientPanel(false);
    }

    if(auth_uid != uid) {
      localStorage.setItem('uid',auth_uid);
      localStorage.setItem("selectedOrgId", orgId)
      this.props.changeRole({role:'admin',id:auth_uid});
      // console.log("admin mode.......",auth_uid);
      this.props.getResourceList('','', '');
      this.props.getcategories();
      this.props.getKeywords();
      this.props.getMyCourses();
      this.props.setSelectedMemberId(null);
      this.props.setClientActivitiesType('Normal')
      if(showActivityHistory) {
        this.props.clearHistoryData();
        this.props.getSelectedActivity(null);
        this.props.showActivityHistoryDetail(false);
        localStorage.removeItem("activityConfirmation");
      }
      this.props.handleHideActivityDetailsPanel(null)
      this.props.handleShowEncounterActivityDetails(false)
      this.props.handleShowEncounterActivityHistory(false)
    }
    let index = key
    if(key === 0){
      this.props.showAssignActivityIFramePanel(false)
    } else if (menu.path === '/app/zoho_analytics'){
      index = this.state.selectedIndex
      const {orgDetail} = this.props
      const zohoUrl = this.getZogoUrl(orgDetail.region)
      var windowReference = window.open();
      windowReference.location = zohoUrl;
      openPopup(windowReference)
      // window.open(zohoUrl)
    } 
    
  }
  
  getZogoUrl(region){
    const userEmail = localStorage.getItem("user_email");
    const {workspaceURL} = this.props
    this.props.analyticsOpenedToNewTab()
    if (workspaceURL) {
      return workspaceURL
    } else {
      if (region === "australia") {
        return `https://accounts.zoho.com.au/signin?LOGIN_ID=${encodeURIComponent(userEmail)}&servicename=ZohoReports&signupurl=https://www.zoho.in/analytics/signup.html`
      } else {
        return `https://accounts.zoho.com/signin?LOGIN_ID=${encodeURIComponent(userEmail)}&servicename=ZohoReports&signupurl=https://www.zoho.in/analytics/signup.html`
      }
    }
    
}

getIcon(menu, windowWidth) {
  let icon = null
  switch (menu.menu_icon) {
    case 'zmdi zmdi-home':
      icon = (<HomeRoundedIcon className="mr-1" />)
      break
    case "zmdi zmdi-tune":
      icon = (<TuneIcon className="mr-1" />)
      break
    case "zmdi zmdi-accounts-alt":
      icon = (<PeopleAltIcon className="mr-1" />)
      break
    case "zmdi zmdi-report":
      icon = (<BarChartIcon className="mr-1"/>)
      break
    case "zmdi zmdi-configurator":
      icon = (<DashboardIcon className="mr-1"/>)
      break
    case 'zmdi zmdi-resource':
      icon = (<CollectionsBookmarkIcon className="mr-1"/>)
      break
    case "zmdi zmdi-chart-donut":
      icon = (<TuneIcon className="mr-1"/>)
      break
    case "zmdi zmdi-assignment-o":
      icon = (<DynamicFeedIcon className="mr-1"/>)
      break
    case "zmdi zmdi-analytics":
      icon = (<ShowChartIcon className="mr-1"/>)
      break
    case "zmdi zmdi-contacts":
      icon = (<ChatIcon className="mr-1"/>)
      break
    case "zmdi zmdi-peoples":
     icon = (<Diversity3RoundedIcon className="mr-1"/>)
     break
    case "zmdi zmdi-peoples" && windowWidth < 768:
     icon = (<Badge className="badgeon-chatlist" badgeContent={totalUnReadMsg} color="primary">
              <ChatIcon className="mr-1"/>
            </Badge>)
     break
    default:
      (<SettingsRoundedIcon className="mr-1"/>)
  }
  return icon
}

  render() {

    const {userRole, selectedMenuItem, allowedModules, isSuperAdmin, isGlobalAdmin, workspaceDetails, hideAnltMenu, isWorkspaceAdmin, networkActivities, profile, showLMSWidget, enableWorkflows} = this.props
    let allowedCategory = navLinks.category1;
    if (allowedModules && (allowedModules.Configurator || isSuperAdmin || isGlobalAdmin ) && (allowedModules.Reporting || allowedModules.LimitedReporting || allowedModules.SubRoleBasedReporting || isWorkspaceAdmin) && allowedModules.LMSInstructor) {
      allowedCategory = navLinks.category3
    } else if (allowedModules && (allowedModules.Configurator || isSuperAdmin || isGlobalAdmin ) && allowedModules.LMSInstructor){
      allowedCategory = navLinks.category2
    } else if (allowedModules && (allowedModules.Configurator || isSuperAdmin || isGlobalAdmin ) && (allowedModules.Reporting || allowedModules.LimitedReporting || allowedModules.SubRoleBasedReporting || isWorkspaceAdmin)){
      allowedCategory = navLinks.category4
    } else if (allowedModules && (allowedModules.Reporting || allowedModules.LimitedReporting || allowedModules.SubRoleBasedReporting || isWorkspaceAdmin) && allowedModules.LMSInstructor){
      allowedCategory = navLinks.category5
    } else if (allowedModules && (allowedModules.Reporting || allowedModules.LimitedReporting || allowedModules.SubRoleBasedReporting || isWorkspaceAdmin)){
      allowedCategory = navLinks.category6
    } else if (allowedModules && allowedModules.Configurator){
      allowedCategory = navLinks.category7
    } else if (allowedModules && allowedModules.LMSInstructor){
      allowedCategory = navLinks.category8
    } 
    const userEmail = profile && profile.email ? profile.email : "";
    let updatedList = [...allowedCategory];
    if(workspaceDetails === "complete" && !hideAnltMenu && userEmail && !userEmail.includes("+")) {
       updatedList = [...allowedCategory];
    } else {
      let itemIndex = updatedList.findIndex(menu => menu.path === '/app/zoho_analytics');
      if(itemIndex >= 0) {
        updatedList.splice(itemIndex,1);
      }
    }

    if (!showLMSWidget) {
      let lmsIndex = updatedList.findIndex(menu => menu.module === 'lms');
        if(lmsIndex >= 0) {
          updatedList.splice(lmsIndex,1);
        }
    }

    if (userRole && userRole === 'Client' || !enableWorkflows) {
      let lmsIndex = updatedList.findIndex(menu => menu.module === 'workflows');
      if(lmsIndex >= 0) {
        updatedList.splice(lmsIndex,1);
      }
    }

    let totalUnReadMsg = 0;
    if(networkActivities && networkActivities.length > 0) {
      totalUnReadMsg = networkActivities.reduce((acc, curr)=> curr.unreadMessages ?  acc + curr.unreadMessages : acc , 0);
      console.log("totalUnReadMsg --> ", totalUnReadMsg);
    }
    
    return (
      <div className="horizontal-menu" data-testid="horizontal-menu" >
        <ul className="list-unstyled nav">
          {updatedList.map((menu, key) => (
            <li data-testid={`horizontal-menu-tab-${menu.module}`} id={menu.path} key={key} className={(userRole && userRole === 'Client' && (menu.path==='/app/chat') ? 'd-none ' : 'nav-item align-self-center ')} onClick={(e) => this.handleMenuItem(e, key, menu)}>
              <NavLink key={key} to={menu.path === '/app/zoho_analytics' ? updatedList[selectedMenuItem] : menu.path} className={"nav-link no-arrow "+((window.location.pathname == menu.path) /* || (selectedMenuItem === key) */? " horizontal-navbar-link":" UnselectTab")}>
                {
                  this.getIcon(menu, this.state.windowWidth)
                }
                { totalUnReadMsg && this.state.windowWidth > 768 && menu.path === "/app/contacts" ? 
                  <Badge className="badgeon-chatlist" badgeContent={totalUnReadMsg} color="primary">
                     {this.state.windowWidth > 768 && <IntlMessages id={menu.menu_title} />}
                  </Badge>
                  : this.state.windowWidth > 768 && <IntlMessages id={menu.menu_title} />
                }
              </NavLink>
            </li>
          )) 
          }
        </ul>
      </div>
    )
  }
}

const mapStateToProps = ({authUser, settings, activitiesList, emailApp, MyCourses, clientsList}) => {
  const { profile, userRole, orgDetail, allowedModules, isSuperAdmin, isGlobalAdmin, workspaceDetails, workspaceURL, hideAnltMenu, isWorkspaceAdmin, enableWorkflows} = authUser
  const { selectedMenuItem } = settings
  const { networkActivities } = activitiesList
  const { showActivityHistory } = emailApp;
  const {showLMSWidget} = MyCourses
  const {clientPanel} = clientsList
  return {userRole, selectedMenuItem, orgDetail, allowedModules, isSuperAdmin, isGlobalAdmin, workspaceDetails, workspaceURL, hideAnltMenu, isWorkspaceAdmin, networkActivities, profile, showActivityHistory, showLMSWidget, enableWorkflows, clientPanel}
}

export default connect(mapStateToProps,{
  changeRole,
  selectMenuItem,
  getResourceList,
  getcategories,getKeywords,
  showClientPanel,
  getMyCourses,
  showAssignActivityIFramePanel,
  analyticsOpenedToNewTab,
  getHelpContactsWithChannel,
  clearHistoryData,
  getSelectedActivity,
  setSelectedMemberId,
  showActivityHistoryDetail,
  handleHideActivityDetailsPanel, 
  handleShowEncounterActivityDetails,
  handleShowEncounterActivityHistory,
  setClientActivitiesType,
  hideNotificationRelatedData,
  hideStartWorkflowPanel,
  clearCourseData
})(HorizontalMenu)
