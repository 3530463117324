import React, { Component } from 'react';

import IconButton from '@mui/material/IconButton';
// reactstrap components
import { Label, Input, FormFeedback } from 'reactstrap';
// Material UI Components
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from '@mui/material/DialogActions';

import CloseIcon from '@mui/icons-material/Close';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Avatar from '@mui/material/Avatar';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import LifeSherpaLoading from "../../LifeSherpaLoading";
import Slide from '@mui/material/Slide';

class AssignedUsers extends Component {

    state = {
        anchorEl: null
    };

    handleClickMenu = (event, role) => {
        this.setState({ anchorEl: event.currentTarget, selectedRole: role });
    };

    handleCloseMenu = () => {
        this.setState({ anchorEl: null, anchorEl1: null, selectedRole: null });
    }

    handleRemoveMember() {
        let { selectedRole } = this.state;
        this.props.handleRemoveMember(selectedRole);
        this.setState({ anchorEl: null, anchorEl1: null, step: null, selectedRole: null });
    }


    isSubmitDisabled() {
        const { userByRoles } = this.props
        if (userByRoles) {
            for (const role in userByRoles) {
                const user = userByRoles[role]['data'];
                if ((userByRoles[role].notifyIfRoleAssignmentPending || userByRoles[role].addNameToExecutionTitle || userByRoles[role].isRequired) && !user) {
                    return true;
                }
            }
        }

        return false
    }



    render() {
        const { anchorEl } = this.state;
        let { workflowname, userByRoles, loading, pendingWorkflowExecution , openWorkflowPendingList, assignableUsersByRoles, workflowExecutionDetail} = this.props;
        // console.log('userByRoles: ', userByRoles);
        // console.log('pendingWorkflowExecution: ', pendingWorkflowExecution);
        // console.log('openWorkflowPendingList: ', openWorkflowPendingList);
        // console.log('workflowExecutionDetail: ', workflowExecutionDetail);
        if(userByRoles) {
            for (const role in userByRoles) {
                if(!userByRoles[role]['data']) {
                    const userId = userByRoles[role].userId;
                    const groupId = userByRoles[role].groupId;
                    if(assignableUsersByRoles && assignableUsersByRoles[role]) {
                        const users = assignableUsersByRoles[role]["users"];
                        const groups = assignableUsersByRoles[role]["groups"];
                        if(userId && users) {
                            const assignedUser = users.find(user => user.userId === userId);
                            if(assignedUser) {
                                userByRoles[role]['data'] = assignedUser;
                                userByRoles[role]['isDisabled'] = true;
                            } 
                        }
                        if(groupId && groups) {
                            const assignedGroup = groups.find(group => group.groupId === groupId);
                            if(assignedGroup) {
                                userByRoles[role]['data'] = assignedGroup;
                                userByRoles[role]['isDisabled'] = true;
                            } 
                        }
                    } 
                }
                if(!userByRoles[role]['data'] && openWorkflowPendingList) {
                    const userId = userByRoles[role].userId;
                    const groupId = userByRoles[role].groupId;
                    const users = pendingWorkflowExecution["users"] || (workflowExecutionDetail && workflowExecutionDetail['users']) || {};
                    const groups = pendingWorkflowExecution["groups"] || (workflowExecutionDetail && workflowExecutionDetail['groups']) || {};
                    if(userId) {
                        userByRoles[role]['isDisabled'] = true;
                        if(users[userId]) {
                            userByRoles[role]['data'] = users[userId];
                        } else {
                            userByRoles[role]['NotFound'] = true;
                        }
                    }
                    if(groupId) {
                        userByRoles[role]['isDisabled'] = true;
                        if(groups[groupId]) {
                            userByRoles[role]['data'] = groups[groupId];
                        } else {
                            userByRoles[role]['NotFound'] = true;
                        }
                    }
                }
            }
        }


        return (
            <React.Fragment>

                <DialogTitle>
                    <div className={`d-flex justify-content-between align-items-center border-bottom bg-lifesherpa pl-2 py-2`}>
                        <Label data-testid="scheduleHeading" className="listItemTitle mb-0 text-white">{openWorkflowPendingList ? "Role Assignment" : "Start Workflow"}</Label>
                        <IconButton className="text-white mr-2" onClick={() => this.props.handleClosePanel()}><CloseIcon /></IconButton>
                    </div>
                </DialogTitle>
                <DialogContent className="m-0 p-0 start-workflow-container ">
                    <LifeSherpaLoading loading={loading} />
                    <Slide direction={"right"} timeout={500} appear={this.props.pageMounted} in={true}>
                        <div>
                            <div className="p-3">
                                <Input
                                    type="text"
                                    value={workflowname}
                                    data-testid="workflow-text-field"
                                    name="workflow-name"
                                    autoCapitalize="none"
                                    id="workflow-name"
                                    className="text-center w-100"
                                    placeholder="Enter Workflow Name!"
                                    invalid={!Boolean(workflowname)}
                                    disabled={openWorkflowPendingList}
                                    onChange={this.props.handleWorkflowNameChange}
                                />
                                {!Boolean(workflowname) ?
                                    <FormFeedback className="text-center">You must supply a valid workflow name to continue</FormFeedback>
                                    : <></>
                                }
                            </div>

                            {userByRoles && Object.keys(userByRoles).length > 0 ? Object.keys(userByRoles).map(role => (
                                <div key={role}>
                                    <div className='d-flex p-2 align-items-center justify-content-between'>
                                        <h3 className='my-2'>
                                            {role}
                                            {(userByRoles[role].notifyIfRoleAssignmentPending || userByRoles[role].addNameToExecutionTitle || userByRoles[role].isRequired) ? 
                                                <span className='text-danger'>*</span>
                                            : ""}
                                        </h3>
                                        <Button onClick={() => this.props.handleOpenAssignUsers(role)} color="primary" variant="contained" className="primary-button" disabled={userByRoles[role].isDisabled}>
                                            {userByRoles[role]["assignmentType"] === "Group" || userByRoles[role]["assignmentType"] === "Group Static" ? "Assign Group" : "Assign User"}
                                        </Button>
                                    </div>
                                    <List className="list-unstyled p-2" >
                                        {userByRoles[role]['data'] ?
                                            <ListItem className={`px-15 py-2 list-card d-flex justify-content-between align-content-center`}>
                                                <div className={`d-flex w-100 align-items-center`}>
                                                    <div className="avatar-wrap w-15 align-self-center position-relative" >
                                                        {userByRoles[role]['data'].profileImageURL ?
                                                            <img src={userByRoles[role]['data'].profileImageURL} data-testid="client-image" className="rounded-circle align-self-center" width="40" height="40" />
                                                            : <Avatar className="align-self-center user-later-avatar" data-testid="client-avatar">{userByRoles[role]['data'].name ? userByRoles[role]['data'].name.charAt(0) : "D"}</Avatar>
                                                        }
                                                    </div>
                                                    <div className=" ml-3">
                                                        <h4 className="mb-0">{userByRoles[role]['data'].name || "Display Name"}</h4>
                                                    </div>
                                                    {userByRoles[role].isDisabled ? 
                                                        <></>
                                                        :
                                                        <div className="d-flex align-items-center ml-auto">
                                                            <IconButton
                                                                onClick={(e) => this.handleClickMenu(e, role)}
                                                                title="More Actions"
                                                                id={`menu-icon-button-${role}`}
                                                            >
                                                                <MoreVertIcon aria-label="More" aria-owns={anchorEl ? `simple-menu-${role}` : null} />
                                                            </IconButton>
                                                            <Menu id={`simple-menu-${role}`} anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={this.handleCloseMenu.bind(this)} >
                                                                <MenuItem disabled={userByRoles[role].isDisabled} className="menuEditItem" onClick={() => this.handleRemoveMember()}>
                                                                {userByRoles[role]["assignmentType"] === "Group" || userByRoles[role]["assignmentType"] === "Group Static" ? "Unassign Group" : "Unassign User"}
                                                                </MenuItem>
                                                                <MenuItem className="menuEditItem" onClick={this.handleCloseMenu.bind(this)}>Close</MenuItem>
                                                            </Menu>
                                                        </div>
                                                    }
                                                </div>
                                            </ListItem>
                                     
                                            :
                                            userByRoles[role]['NotFound'] && !loading ?
                                            <div className='d-flex align-items-center my-0 container-border py-0 mx-auto justify-content-between'>
                                                <div className='text-center w-100 my-4 py-4'>
                                                    {userByRoles[role]["assignmentType"] === "Group" || userByRoles[role]["assignmentType"] === "Group Static" ? `You don't have permission to see the assigned group`:  `You don't have permission to see the assigned user`}
                                                </div>
                                            </div>
                                            :
                                            <div className='d-flex align-items-center my-0 container-border py-0 mx-auto justify-content-between'>
                                                <div className='text-center w-100 my-4 py-4'>
                                                    {userByRoles[role]["assignmentType"] === "Group" || userByRoles[role]["assignmentType"] === "Group Static" ? `Please assign group to ${role} role`:  `Please assign user to ${role} role`}
                                                </div>
                                            </div>
                                        }

                                    </List>
                                </div>
                            ))
                                :
                                <div className='d-flex px-2 pt-2 align-items-center justify-content-between'>
                                    <h3 className='my-2'>Workflow Roles Not Found</h3>

                                </div>
                            }
                        </div>
                    </Slide>



                </DialogContent>
                <DialogActions className="p-2 d-flex justify-content-center filter-buttons-styles">
                    {userByRoles && Object.keys(userByRoles).length > 0 ?
                        <Button color="primary" variant="contained" className=" w-40 filter-button-apply" onClick={() => this.props.handleSubmit()} disabled={!workflowname || this.isSubmitDisabled()}>
                            Submit
                        </Button>
                        :
                        <></>
                    }
                </DialogActions>
            </React.Fragment>
        );
    }
}

export default AssignedUsers;