/**
* Chat
*/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import Hidden from '@mui/material/Hidden';
import SweetAlert from 'react-bootstrap-sweetalert';
import {
	getWorkflowHistory,
	hideStartWorkflowPanel,
	openSkipWorkflow, 
	openCancelWorkflowConfirmation,
	cancelWorkflowInstance,
	skipWorkflowInstanceTasks,
	getUserWorkflowHistory,
	changeRole,
	setSelectedMemberId,
	setClientActivitiesType,
	executeActivityWithAchievementId,
	selectActivityId,
	handlEditor,
	showExecuteActivityPannel,
	handleUserWorkflowPanel,
	setActivityReminder,
	getWorkflowHistoryDetail,
	getUserWorkflowHistoryDetail,
	getSubWorkflowHistoryDetail,
	getUserSubWorkflowHistoryDetail,
	removeWorkflowDetailData,
	showExecutionHistoryPendingRolePopup,
	setSelectedSubWorkflow,
	notifyWorkflowGroupRoleAssignmentActivity,
	executeActivity
} from '../../actions';

// components
import WorkflowHistoryDetail from './components/WorkflowHistoryDetail';
import WorkflowHistoryListPanel from './components/WorkflowHistoryListPanel';
import SkipWorkflowTask from './components/SkipWorkflowTask';
import LifeSherpaLoading from '../LifeSherpaLoading';
import moment from 'moment';
import ExecutionPendingRoleAssign from "../../components/Workflows/components/ExecutionPendingRoleAssign"
import LegendComponent from '../AchievementHistoryList/components/LegendComponent';

class WorkflowExecutionHistory extends Component {

	state = {
		openPanel: "list",
		// workflowCancelConfrimation: false,
		alertDescription: "",
		// openSkipTaskview: false,
		selectedWorkflowTasks: [],
		notify: false,
		achievementId: null, 
		userId: null,
		organization: null,
		selectedTaskName: null,
		clientName: null,
		workflow: {},
		showLegend: false,
		isSubWorkflowOpened: false
	};

	componentDidMount() {
		const { selectedWorkflow, userId, selectedWorkflowStatus} = this.props;
		const {organization} = selectedWorkflow;
		if (userId) {
			this.props.getUserWorkflowHistory({workflowId: selectedWorkflow.id, userId, orgId: organization, selectedWorkflowStatus})
		} else {
			this.props.getWorkflowHistory({ workflowId: selectedWorkflow.id, orgId: organization, allHistoryData: true, selectedWorkflowStatus })
		}
	}

	WorkflowHistoryHandler(event) {
		const {workflow, isSubWorkflowOpened} = this.state
		const { userId, subWorkflowDetailData } = this.props;
		if(event.data === "Load Workflow List") {
		  console.log("Notification post message received ..."); 
		  if (isSubWorkflowOpened) {
			const {id, workflowId, organization} = subWorkflowDetailData
			if (userId) {
				this.props.getUserSubWorkflowHistoryDetail({subWorkflowId: workflowId, userId, orgId: organization, executionId: id})
			} else {
				this.props.getSubWorkflowHistoryDetail({subWorkflowId: workflowId, orgId: organization, executionId: id})
			}
		  } else {
			const {id, workflowId, organization} = workflow
			if (userId) {
				this.props.getUserWorkflowHistory({workflowId, userId, orgId: organization, executionId: id})
			} else {
				this.props.getWorkflowHistory({ workflowId, orgId: organization, allHistoryData: true, executionId: id})
			}
		  }
		 
		  window.removeEventListener("message",  this.WorkflowHistoryHandler)
		}    
	}

	componentWillUnmount() {
		window.removeEventListener("message",  this.WorkflowHistoryHandler)
	}

	handleCloseWorkflowHistory(){
		this.props.hideStartWorkflowPanel()
		this.props.removeWorkflowDetailData()
	}

	handleOpenWorkflowList(){
		this.setState({openPanel: "list"})
	}

	handleOpenWorkflowDetails(selectedWorkflow){
		const {id, workflowId, organization} = selectedWorkflow
		const { userId } = this.props;
		this.setState({openPanel: "details", workflow: selectedWorkflow}, () => {
			if (userId) {
				this.props.getUserWorkflowHistoryDetail({workflowId, userId, orgId: organization, executionId: id})
			} else {
				this.props.getWorkflowHistoryDetail({workflowId, orgId: organization, executionId: id})
			}
		})
	}

	handleSkipWorkflowTask() {
		this.props.openSkipWorkflow(true)
	}

	handleCancelWorkflow(workflow) {
		console.log("workflow: ", workflow)
		const alertDescription = `Are you sure? You want to cancel ${workflow?.executionName || workflow?.name} workflow.`
		this.setState({alertDescription, workflow}, () => {
			this.props.openCancelWorkflowConfirmation(true)
		})
	}

	handleClose() {
		this.setState({alertDescription: "", notify: false, achievementId: null, userId: null, organization: null, selectedTaskName:null, clientName: null, isSubWorkflowOpened: false, groupRolePendingTaskId: null, groupId: null, groupName: null, includeUsers: null}, () => {
			this.props.openSkipWorkflow(false)
			this.props.openCancelWorkflowConfirmation(false)
		})
	}

	handleConfirm() {
		const {workflow} = this.state
		const {organization} = workflow
		const { userId } = this.props;
		console.log("selectedWorkflowItem: ", workflow)
		this.setState({alertDescription: ""}, () => {
			this.props.openSkipWorkflow(false)
			this.props.openCancelWorkflowConfirmation(false)
			this.props.cancelWorkflowInstance(workflow.workflowId, workflow.id, organization, userId)
		})
	}

	handleNotifyClient() {
		const {achievementId, userId, organization, groupRolePendingTaskId, groupId } = this.state
		this.setState({alertDescription: "", notify: false, achievementId: null, userId: null, organization: null, selectedTaskName: null, clientName: null, groupRolePendingTaskId: null, groupId: null, groupName: null, includeUsers: null}, () => {
			if(groupRolePendingTaskId) {
				if(userId) {
					this.props.setActivityReminder(userId, groupRolePendingTaskId, organization, false)
				} else {
					this.props.notifyWorkflowGroupRoleAssignmentActivity(groupId, groupRolePendingTaskId, organization)
				}
			} else {
				this.props.setActivityReminder(userId, achievementId, organization, true)
			}
			
		})
	}

	showNotifyAlert({task, taskId, isSubWorkflowOpened}) {
		let {workflowExecutionDetail, subWorkflowDetailData, groupMembersDetails} = this.props
		const updatedSubWorkflow = isSubWorkflowOpened && subWorkflowDetailData && workflowExecutionDetail ? {...subWorkflowDetailData, organization: workflowExecutionDetail.organization} : workflowExecutionDetail;
		if(!updatedSubWorkflow) {
			return;
		}
		const {activityExecutedBy, activityAssignedTo, organization, type} = task.parameters || {};
		const {userId, groupId} = activityAssignedTo;
		const { role, executedByUserId } = activityExecutedBy || {};
		if(type === "groupRoleAssignment") {
			let groupName, clientName = null;
			if(executedByUserId) {
				const selectedClient = groupMembersDetails ? groupMembersDetails.filter(client => client.userId === executedByUserId)[0] : null;
    			clientName = selectedClient && (selectedClient.name || selectedClient.displayName || selectedClient.firstName) ? (selectedClient.name || selectedClient.displayName || selectedClient.firstName) : 'Client';
			} else {
				groupName = updatedSubWorkflow.groups && updatedSubWorkflow.groups[groupId] && updatedSubWorkflow.groups[groupId].name || "Group";
			}
			const groupRolePendingTaskId = `${groupId},${updatedSubWorkflow.id},${taskId}`;
			const includeUsers = updatedSubWorkflow.roles && updatedSubWorkflow.roles[role] && updatedSubWorkflow.roles[role].includeUsers;
			this.setState({groupRolePendingTaskId, groupId, userId: executedByUserId, organization, notify: true, selectedTaskName: task.name, groupName, clientName, includeUsers});
		} else {
			const clientName = role && updatedSubWorkflow && updatedSubWorkflow.roles && updatedSubWorkflow.roles[role] && updatedSubWorkflow.roles[role].user ? updatedSubWorkflow.roles[role].user.name : "Client"
			this.setState({achievementId: task.achievementId, userId, organization, notify: true, selectedTaskName: task.name, clientName})
		}
    	
	}

	handleSubmitSkipTask(tasks) {
		const {workflowExecutionDetail, userId} = this.props
		const taskIds = tasks.map(item => {
			return item.taskId
		})
		const workflowTasks = {tasks: taskIds}
		const orgId = workflowExecutionDetail.organization
		this.setState({alertDescription: ""}, () => {
			this.props.openSkipWorkflow(false)
			this.props.openCancelWorkflowConfirmation(false)
			this.props.skipWorkflowInstanceTasks(workflowExecutionDetail.workflowId, workflowExecutionDetail.id, workflowTasks, orgId, userId)
		})
	}

	/* Execute Workflow Task*/
    handleExecuteActivity = ({activity, taskId, isSubWorkflowOpened}) => {
		const {workflowExecutionDetail} = this.props;
		if (workflowExecutionDetail && workflowExecutionDetail.organization) {
			localStorage.setItem("selectedOrgId", workflowExecutionDetail.organization)
		}
        const {activityAssignedTo, activityExecutedBy = {}, type} = activity.parameters
        let {userId, groupId} = activityAssignedTo;
		const {executedByUserId} = activityExecutedBy;
        let activityId;
        if(type === "groupRoleAssignment") {
            userId = executedByUserId || localStorage.getItem("uid");
            const executionId = workflowExecutionDetail.id;
            activityId = `${groupId},${executionId},${taskId}`;
        }
        let date = moment().format('YYYY-MM-DD HH:mm:ss Z');
        let auth_uid = localStorage.getItem('auth_uid');

        if(userId != auth_uid) {
            localStorage.setItem('uid',userId);
            this.props.changeRole({ role: 'client', id: userId })
            this.props.setSelectedMemberId(userId)
            this.props.setClientActivitiesType("Touchpoint");
        } else {
			localStorage.setItem('uid',userId);
		}

        this.setState({notifyClient: false, isSubWorkflowOpened}, () => {
			if(activityId) {
                this.props.executeActivity(activityId, date, userId);
                this.props.selectActivityId(activityId);
            } else {
                this.props.executeActivityWithAchievementId(activity.achievementId, date, userId);
                this.props.selectActivityId(activity.achievementId);
            }
            this.props.handlEditor(true);
            this.props.showExecuteActivityPannel("start")
            this.props.handleUserWorkflowPanel()
			// adding listener
			window.addEventListener("message",  this.WorkflowHistoryHandler.bind(this));
        })
    }

	handleOpenWorkflowPendingRoleList = (workflow) => {
        console.log("workflow :", workflow)
        if(workflow.organization) {
            localStorage.setItem("selectedOrgId", workflow.organization);
        }
        this.props.showExecutionHistoryPendingRolePopup(true);
    }

	handleLengendInfo() {
		this.setState({showLegend: !this.state.showLegend})
	}

	render() {
		const { openPanel, alertDescription, selectedWorkflowTasks, notify, selectedTaskName, clientName, showLegend, groupName, includeUsers } = this.state;
		const { isMobileView, popupThemeClass, workflowCancelConfrimation, openSkipTaskview, workflowHistoryLoading, cancelWorkflowLoading, skipWorkflowTasksLoading, userRole, workflowExecutionDetail, workflowDetailLoading, showPendingRolePanel, subWorkflowDetailData, selectedSubWorkflow, subWorkflowLoading } = this.props;
		const UpdatedSubWorkflow = subWorkflowDetailData && workflowExecutionDetail ? {...subWorkflowDetailData, organization: workflowExecutionDetail.organization} : null

		return (  
			<div className="achievement-history-list-container lifesherpa-session-theme-support w-100">
				<LifeSherpaLoading loading={workflowHistoryLoading || cancelWorkflowLoading || skipWorkflowTasksLoading}/>
				<Hidden mdUp>
					{openPanel === "details" ?
						<WorkflowHistoryDetail
							handleOpenWorkflowList={this.handleOpenWorkflowList.bind(this)}
							selectedWorkflowItem={workflowExecutionDetail}
							handleCloseWorkflowHistory={this.handleCloseWorkflowHistory.bind(this)}
							fullScreen={false}
							handleSkipWorkflowTask={this.handleSkipWorkflowTask.bind(this)}
							userRole={userRole}
							handleExecuteActivity={this.handleExecuteActivity.bind(this)}
							showNotifyAlert={this.showNotifyAlert.bind(this)}
							workflowDetailLoading={workflowDetailLoading}
							handleCancelWorkflow={this.handleCancelWorkflow.bind(this)}
							handleOpenWorkflowPendingRoleList={this.handleOpenWorkflowPendingRoleList.bind(this)}
							userId={this.props.userId}
							getUserSubWorkflowHistoryDetail={this.props.getUserSubWorkflowHistoryDetail}
							getSubWorkflowHistoryDetail={this.props.getSubWorkflowHistoryDetail}
							subWorkflowDetailData={subWorkflowDetailData}
							selectedSubWorkflow={selectedSubWorkflow}
							setSelectedSubWorkflow={this.props.setSelectedSubWorkflow}
						/>
						:
						<WorkflowHistoryListPanel
							handleOpenWorkflowDetails={this.handleOpenWorkflowDetails.bind(this)}
							handleCloseWorkflowHistory={this.handleCloseWorkflowHistory.bind(this)}
							fullScreen={false}
							handleLengendInfo={this.handleLengendInfo.bind(this)}
							selectedWorkflowStatus={this.props.selectedWorkflowStatus}
						/>
					}
				</Hidden>
				<Hidden mdDown>
					<div className="d-flex activities-history-full-screen">
						<WorkflowHistoryListPanel
							handleOpenWorkflowDetails={this.handleOpenWorkflowDetails.bind(this)}
							handleCloseWorkflowHistory={this.handleCloseWorkflowHistory.bind(this)}
							fullScreen={true}
							handleLengendInfo={this.handleLengendInfo.bind(this)}
							selectedWorkflowStatus={this.props.selectedWorkflowStatus}
						/>
						<div className="w-100">
							<WorkflowHistoryDetail
								handleOpenWorkflowList={this.handleOpenWorkflowList.bind(this)}
								selectedWorkflowItem={workflowExecutionDetail}
								handleCloseWorkflowHistory={this.handleCloseWorkflowHistory.bind(this)}
								fullScreen={true}
								handleSkipWorkflowTask={this.handleSkipWorkflowTask.bind(this)}
								userRole={userRole}
								handleExecuteActivity={this.handleExecuteActivity.bind(this)}
								showNotifyAlert={this.showNotifyAlert.bind(this)}
								workflowDetailLoading={workflowDetailLoading}
								handleCancelWorkflow={this.handleCancelWorkflow.bind(this)}
								handleOpenWorkflowPendingRoleList={this.handleOpenWorkflowPendingRoleList.bind(this)}
								userId={this.props.userId}
								getUserWorkflowHistoryDetail={this.props.getUserWorkflowHistoryDetail}
								getWorkflowHistoryDetail={this.props.getWorkflowHistoryDetail}
								getUserSubWorkflowHistoryDetail={this.props.getUserSubWorkflowHistoryDetail}
								getSubWorkflowHistoryDetail={this.props.getSubWorkflowHistoryDetail}
								subWorkflowDetailData={subWorkflowDetailData}
								selectedSubWorkflow={selectedSubWorkflow}
								subWorkflowLoading={subWorkflowLoading}
								setSelectedSubWorkflow={this.props.setSelectedSubWorkflow}
							/>
						</div>
					</div>
				</Hidden>
				{workflowCancelConfrimation ?
					<SweetAlert
						warning
						showCancel
						btnSize="sm"
						customClass="text-white"
						confirmBtnText="Yes"
						cancelBtnText="No"
						confirmBtnBsStyle="success"
						cancelBtnBsStyle='danger'
						title="Warning!"
						onConfirm={() => this.handleConfirm()}
						onCancel={() => this.handleClose()}
						>
						<div className="sweet-alert-text">{alertDescription}</div>
					</SweetAlert>
					: notify ?
					<SweetAlert
						warning
						showCancel
						btnSize="sm"
						customClass="text-white"
						confirmBtnText="Yes"
						cancelBtnText="No"
						confirmBtnBsStyle="success"
						cancelBtnBsStyle='danger'
						title="Warning!"
						onConfirm={() => this.handleNotifyClient()}
						onCancel={() => this.handleClose()}
						>
						<div className="sweet-alert-text">
							{
								groupName? 
								`Would you like to notify ${includeUsers === "onlyAdmins" ? `Admin role users of group - "${groupName || "Group"}"`: includeUsers === "onlyClients" ? `General role users of group - "${groupName || "Group"}"` : includeUsers ? `All General and Admin users of group - "${groupName || "Group"}"` : ""} to start "${selectedTaskName}"?`
								: 
								`Would you like to notify "${clientName || "Client"}" to start "${selectedTaskName}"?`
							}
							{}
						</div>
					</SweetAlert>
					: openSkipTaskview ?
						<SkipWorkflowTask
							openSkipTaskview={openSkipTaskview}
							selectedWorkflowItem={workflowExecutionDetail}
							selectedWorkflowTasks={selectedWorkflowTasks}
							popupThemeClass={popupThemeClass}
							isMobileView={isMobileView}
							handleClose={() => this.handleClose()}
							handleSubmit={this.handleSubmitSkipTask.bind(this)}
						/>
					:showPendingRolePanel ? 
                    	<ExecutionPendingRoleAssign selectedWorkflow={workflowExecutionDetail} subWorkflowDetailData={UpdatedSubWorkflow} userId={this.props.userId}/>
					: <></>
				}
				<LegendComponent 
					showLegend={showLegend} 
					handleClose={this.handleLengendInfo.bind(this)} 
					popupThemeClass={popupThemeClass} 
					isWorkflowLegend={true}
					/>
			</div>
		);
	}
}

const mapStateToProps = ({ workflowData, settings, authUser, GroupList }) => {
	const { popupThemeClass, isMobileView } = settings;
	const { selectedWorkflow, showWorkflowHistory,  workflowHistory, workflowHistoryLoading, openSkipTaskview, workflowCancelConfrimation, cancelWorkflowLoading, skipWorkflowTasksLoading, workflowExecutionDetail,workflowDetailLoading, selectedWorkflowItem, showPendingRolePanel, subWorkflowDetailData, selectedSubWorkflow, subWorkflowLoading, selectedWorkflowStatus} = workflowData
	const { userRole, user_no_group } = authUser
	const { groupMembersDetails } = GroupList;

	return { selectedWorkflow, showWorkflowHistory,  workflowHistory, workflowHistoryLoading, popupThemeClass, isMobileView, openSkipTaskview, workflowCancelConfrimation, cancelWorkflowLoading, skipWorkflowTasksLoading, userRole, groupMembersDetails, user_no_group, workflowExecutionDetail,workflowDetailLoading, selectedWorkflowItem, showPendingRolePanel, subWorkflowDetailData, selectedSubWorkflow, subWorkflowLoading, selectedWorkflowStatus };
}

export default withRouter(connect(mapStateToProps, {
	getWorkflowHistory,
	hideStartWorkflowPanel,
	openSkipWorkflow, 
	openCancelWorkflowConfirmation,
	cancelWorkflowInstance,
	skipWorkflowInstanceTasks,
	getUserWorkflowHistory,
	changeRole,
	setSelectedMemberId,
	setClientActivitiesType,
	executeActivityWithAchievementId,
	selectActivityId,
	handlEditor,
	showExecuteActivityPannel,
	handleUserWorkflowPanel, 
	setActivityReminder,
	getWorkflowHistoryDetail,
	getUserWorkflowHistoryDetail,
	removeWorkflowDetailData,
	showExecutionHistoryPendingRolePopup,
	getSubWorkflowHistoryDetail,
	getUserSubWorkflowHistoryDetail,
	setSelectedSubWorkflow,
	notifyWorkflowGroupRoleAssignmentActivity,
	executeActivity
})(WorkflowExecutionHistory));

