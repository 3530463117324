import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

LinearProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate and buffer variants.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

export default function LinearProgressWithValueLabel(props) {
  const [progress, setProgress] = useState(10);
  const timerRef = useRef(null);
  const { loading, handleClose} = props

  useEffect(() => {
    // Only start the interval if progress is less than 90
    if (progress < 90) {
      timerRef.current = setInterval(() => {
        setProgress((prevProgress) => {
          const newProgress = Math.min(prevProgress + 10, 90);
          return newProgress !== prevProgress ? newProgress : prevProgress;
        });
      }, 800);
    }

    return () => {
      // Cleanup interval on component unmount
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, [progress]);

  useEffect(() => {
    // If loading is false and progress is 90, complete to 100
    if (!loading && progress === 90) {
      setProgress(100);
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
      handleClose()
      // Attempt to close the window once progress is 100
      // try {
      //   window.close();
      // } catch (e) {
      //   console.error("Close window failed", e);
      // }
    }
  }, [loading, progress]);

  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgressWithLabel value={progress} />
    </Box>
  );
}
